import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Select from "react-select";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import carFleetService from "../../../services/carFleetService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import fuelService from "../../../services/fuelService";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  fuel: any;
  bio_fuel: any;
  description: string;
}

export const CarFleetEdit = () => {
  const history = useHistory();

  const [fuels, setFuels] = useState<any>([]);
  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.carFleet_id){
      getCarFleetsById(item.carFleet_id)
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  useEffect(() => {
    getFuels()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newCarFleet = {
        name: formData.name,
        description: formData.description,
        fuel_id: formData.fuel ? formData.fuel.value : undefined,
        bio_fuel_id: formData.bio_fuel ? formData.bio_fuel.value : undefined
      };

      if (carFleetSelected?.id) {
        const result = await carFleetService.updateById(
          carFleetSelected.id,
          newCarFleet
        );

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await carFleetService.create(newCarFleet);

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getFuels() {
    const result = await fuelService.getByParams({ limit: 999999, page: 1 });

    const options = result.itens.map((fuel: any) => {
      return {
        label: fuel.name,
        value: fuel.id,
      };
    });

    setFuels(options);
  }

  async function getCarFleetsById(id: number) {
    const carFleet = await carFleetService.getById(id);

    setValue("name", carFleet?.name);
    setValue("description", carFleet?.description);

    if (carFleet?.fuel){
      setValue("fuel", {value: carFleet?.fuel.id, label: carFleet?.fuel.name});
    }

    if (carFleet?.bioFuel){
      setValue("bio_fuel", {value: carFleet?.bioFuel.id, label: carFleet?.bioFuel.name});
    }

    setCarFleetSelected(carFleet)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Tipo de frota de veículos" />
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 30, marginLeft: 50, marginRight: 50  }} >
          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Nome
          </label>

          <input style={{width: '95%'}}
                {...register("name", { required: true })}/>
                      
          {errors.name && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Combustível fóssil
          </label>

          <Controller control={control} name="fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("fuel")}
                options={fuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Biocombustível
          </label>

          <Controller control={control} name="bio_fuel"
            render={({ field }) => (
              <Select placeholder="[Selecione]" {...field} styles={{ control: (styles) => ({
                    ...styles, width: "100%", height: 40, marginTop: 10,
                    backgroundColor: "rgb(246, 246, 246)"}),
                  option: (styles) => { return { ...styles, color: "#31363F" };},
                }} defaultValue={getValues("bio_fuel")}
                options={fuels} isClearable={true}                      
              />
            )}
          />
                      
          {errors.fuel && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
          
          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Descrição
          </label>

          <textarea style={{width: '95%', height: 100}}
                {...register("description")}/>
                      
          {errors.description && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
