import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import seasonalDataService from "../../services/seasonalDataService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const SeasonalData = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [seasonalDatas, setSeasonalDatas] = useState<any>([]);
  const [seasonalDataSelected, setSeasonalDataSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getSeasonalDatas(page: number, filter: string) {
    const result = await seasonalDataService.getByParams({ limit: 25, page, filter: filter });
    setSeasonalDatas(result);

    setCurrentPage(page)
  }

  useEffect(() => {
    getSeasonalDatas(1, searchDebounced);
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    getSeasonalDatas(1, searchDebounced)
  }, [searchDebounced]);

  async function handleDeleteSeasonalData() {
    if (seasonalDataSelected) {
      const result = await seasonalDataService.deleteById(
        seasonalDataSelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getSeasonalDatas(1, searchDebounced);

        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  async function getSeasonalDatasById(id: number) {
    const seasonalData = await seasonalDataService.getById(id);

    setSeasonalDataSelected(seasonalData)
  }

  function redirectToEdit(seasonalData?: any) {
    if (seasonalData){
      history.push({
          pathname: '/dados-sazonais/edicao',
          state: { seasonalData_id: seasonalData.id }
      });
    }else{
      history.push('/dados-sazonais/edicao')
    }
  }

  function handleOpenModalDelete(seasonalData?: any) {
    setOpenModalDelete(!openModalDelete);

    if (seasonalData) {
      getSeasonalDatasById(seasonalData.id);
    } else {
      setSeasonalDataSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getSeasonalDatas(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getSeasonalDatas(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Dados Sazonais" />

        <input
          className="input-search"
          placeholder="Pesquisar dados sazonais por ano"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo registro
        </Button>
      </div>

      {seasonalDatas?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
              maxHeight: "calc(100vh - 130px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Ano"}
                  </th>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Descrição"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {seasonalDatas.itens.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span className="title-text-underline">
                            {item.year}
                          </span>
                        </td>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span>
                            {item.description}
                          </span>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <button
                              className="button-delete"
                              style={{ marginRight: 5 }}
                              onClick={() => handleOpenModalDelete(item)}
                            >
                              Excluir
                            </button>
                            <button
                              className="button-edit"
                              onClick={() => redirectToEdit(item)}
                            >
                              Editar
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
              {seasonalDatas?.itens?.length > 0 && (
                <Pagination
                  total={seasonalDatas.total}
                  limit={25}
                  goNext={goNext}
                  goPrev={goPrev}
                  currentPage={currentPage}
                  length={seasonalDatas.itens.length}
                />
              )}

          </div>
        )
      }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={seasonalDataSelected.name}
          handleOk={handleDeleteSeasonalData}
        />
      )}
    </Container>
  );
};
