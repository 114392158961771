import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import useAuth from "../../../../../hooks/useAuth";
import inventoryService from "../../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import { formatPrice3 } from "../../../../../utils/transform";
import scope1Service from "../../../../../services/scope1Service";
import { Modal } from "../../../../../components/Modal";
import treatmentTypeService from "../../../../../services/treatmentTypeService";
import Parser from 'html-react-parser';
import scopeService from "../../../../../services/scopeService";
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import { toast } from "react-hot-toast";
import { Loading } from "../../../../../components/Loading";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";

export const Effluents = () => {
  const history = useHistory();

  const [files, setFiles] = useState<any>([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [passo01, setPasso01] = useState<any>();
  const [passo02, setPasso02] = useState<any>();
  const [passo03, setPasso03] = useState<any>();
  const [passo04, setPasso04] = useState<any>();
  const [passo05, setPasso05] = useState<any>();
  const [passo06, setPasso06] = useState<any>();
  const [passo07, setPasso07] = useState<any>();
  const [passo08, setPasso08] = useState<any>();
  const [passo09, setPasso09] = useState<any>();

  const [openModal, setOpenModal] = useState(false);
  const [ignoreFill, setIgnoreFill] = useState(true);
  const [secondTreatment, setSecondTreatment] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  const [inventorySelected, setInventorySelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const location = useLocation();

  const [treatmentTypes, setTreatmentTypes] = useState<any>([]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gases, setGases] = useState<any>([]);
  const [changeScope, setChangeScope] = useState<string | undefined>();

  const { isCommonUser } = useAuth()
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstFactor, setFirstFactor] = useState<any>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstFactorMethane, setFirstFactorMethane] = useState<any>();

  const [emissionCh4, setEmissionCh4] = useState<any>();
  const [emissionN2o, setEmissionN2o] = useState<any>();
  const [emissionCo2, setEmissionCo2] = useState<any>();
  const [emissionCo2Bio, setEmissionCo2Bio] = useState<any>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({
        pathname: '/inventario/escopo-1',
      });
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'efluentes_liquidos', 'efluentes')

    setTitle(item.label)
    setGuidelines(item.guidelines)

    const extra = await scopeService.getByAllSlug(1, 'efluentes_liquidos');

    setPasso01(extra.passo_01)
    setPasso02(extra.passo_02)
    setPasso03(extra.passo_03)
    setPasso04(extra.passo_04)
    setPasso05(extra.passo_05)
    setPasso06(extra.passo_06)
    setPasso07(extra.passo_07)
    setPasso08(extra.passo_08)
    setPasso09(extra.passo_09)
  }

  function handleOpenInfo () {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      let nitrogen_removed = getValues('nitrogen_removed')
      let amount_n2_nitrogen = getValues('amount_n2_nitrogen')
      let treatment_first_id = getValues('treatment_first_id')
      let treatment_second_id = getValues('treatment_first_id')
      let n2o_emission_factor = getValues('n2o_emission_factor')
      let effluent_degradable = getValues('effluent_degradable')
      let nitrogen_removed_after = getValues('nitrogen_removed_after')
      let amount_n2_nitrogen_after = getValues('amount_n2_nitrogen_after')
      let effluent_degradable_after = getValues('effluent_degradable_after')
      let generated_liquid_effluent = getValues('generated_liquid_effluent')
      let n2o_emission_factor_after = getValues('n2o_emission_factor_after')
      let effluent_degradable_unity = getValues('effluent_degradable_unity')
      let two_anaerobic_treatments = getValues('two_anaerobic_treatments')
      let ch4_amount_recovered_methane = getValues('ch4_amount_recovered_methane')
      let destination_recovered_biogas = getValues('destination_recovered_biogas')
      let generated_liquid_effluent_after = getValues('generated_liquid_effluent_after')
      let effluent_degradable_after_unity = getValues('effluent_degradable_after_unity') 
      let destination_recovered_biogas_after = getValues('destination_recovered_biogas_after')
      let ch4_amount_recovered_methane_after = getValues('ch4_amount_recovered_methane_after')
  
      treatment_first_id = treatment_first_id ? parseInt(treatment_first_id) : 0
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      treatment_second_id = treatment_second_id ? parseInt(treatment_second_id) : 0
      two_anaerobic_treatments = two_anaerobic_treatments ? parseInt(two_anaerobic_treatments) : 0
      effluent_degradable_unity = effluent_degradable_unity ? parseInt(effluent_degradable_unity) : 0
      effluent_degradable_after_unity = effluent_degradable_after_unity ? parseInt(effluent_degradable_after_unity) : 0
  
      nitrogen_removed = nitrogen_removed ? parseFloat(nitrogen_removed.replace(".", "").replace(",", ".")) : 0
      amount_n2_nitrogen = amount_n2_nitrogen ? parseFloat(amount_n2_nitrogen.replace(".", "").replace(",", ".")) : 0
      n2o_emission_factor = n2o_emission_factor ? parseFloat(n2o_emission_factor.replace(".", "").replace(",", ".")) : 0
      effluent_degradable = effluent_degradable ? parseFloat(effluent_degradable.replace(".", "").replace(",", ".")) : 0
      nitrogen_removed_after = nitrogen_removed_after ? parseFloat(nitrogen_removed_after.replace(".", "").replace(",", ".")) : 0
      amount_n2_nitrogen_after = amount_n2_nitrogen_after ? parseFloat(amount_n2_nitrogen_after.replace(".", "").replace(",", ".")) : 0
      effluent_degradable_after = effluent_degradable_after ? parseFloat(effluent_degradable_after.replace(".", "").replace(",", ".")) : 0
      generated_liquid_effluent = generated_liquid_effluent ? parseFloat(generated_liquid_effluent.replace(".", "").replace(",", ".")) : 0
      n2o_emission_factor_after = n2o_emission_factor_after ? parseFloat(n2o_emission_factor_after.replace(".", "").replace(",", ".")) : 0
      ch4_amount_recovered_methane = ch4_amount_recovered_methane ? parseFloat(ch4_amount_recovered_methane.replace(".", "").replace(",", ".")) : 0
      destination_recovered_biogas = destination_recovered_biogas ? parseFloat(destination_recovered_biogas.replace(".", "").replace(",", ".")) : 0
      generated_liquid_effluent_after = generated_liquid_effluent_after ? parseFloat(generated_liquid_effluent_after.replace(".", "").replace(",", ".")) : 0
      destination_recovered_biogas_after = destination_recovered_biogas_after ? parseFloat(destination_recovered_biogas_after.replace(".", "").replace(",", ".")) : 0
      ch4_amount_recovered_methane_after = ch4_amount_recovered_methane_after ? parseFloat(ch4_amount_recovered_methane_after.replace(".", "").replace(",", ".")) : 0

      const scope = {
        status: 2,
        reason: formData.reason,
        emission_ch4: emissionCh4,
        emission_n2o: emissionN2o,
        emission_co2e: emissionCo2,
        evidence: formData.evidence,
        emission_co2_bio: emissionCo2Bio,
        ignore_fill: formData.ignore_fill,
        nitrogen_removed: nitrogen_removed,
        amount_n2_nitrogen: amount_n2_nitrogen,
        treatment_first_id: treatment_first_id,
        treatment_second_id: treatment_first_id,
        n2o_emission_factor: n2o_emission_factor,
        effluent_degradable: effluent_degradable,
        nitrogen_removed_after: nitrogen_removed_after,
        amount_n2_nitrogen_after: amount_n2_nitrogen_after,
        two_anaerobic_treatments: two_anaerobic_treatments,
        effluent_degradable_after: effluent_degradable_after,
        generated_liquid_effluent: generated_liquid_effluent,
        n2o_emission_factor_after: n2o_emission_factor_after,
        effluent_degradable_unity: effluent_degradable_unity,
        ch4_amount_recovered_methane: ch4_amount_recovered_methane,
        destination_recovered_biogas: destination_recovered_biogas,
        generated_liquid_effluent_after: generated_liquid_effluent_after,
        effluent_degradable_after_unity: effluent_degradable_after_unity, 
        destination_recovered_biogas_after: destination_recovered_biogas_after,
        ch4_amount_recovered_methane_after: ch4_amount_recovered_methane_after
      };

      const result = await scope1Service.saveLiquidEffluentsItem(
          inventorySelected.company.id, inventorySelected.year, scope);

      if (result && !result.error) {
        history.goBack()
        
        setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }

    setLoadingModal(false)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope:number) {
    setLoadingModal(true)

    const types = await treatmentTypeService.getByParams({ limit: 9000, page: 1 });

    setTreatmentTypes(types?.itens);

    if (types?.itens.length > 0){
      handleChangeTreatmentFirst(types?.itens[0].id)
      handleChangeTreatmentSecond(types?.itens[0].id)
    }

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getLiquidEffluentsItem(company_id, year)
    
    const result = await inventoryService.getFiles(
      inventory.company.id, inventory.year, 1, 'efluentes_liquidos', 'efluentes');

    setFiles(result.files)

    if (entity){
      setValue("reason", entity.reason)
      setValue("evidence", entity.evidence)
      setValue("ignore_fill", entity.ignore_fill)

      setValue("treatment_first_id", entity.treatment_first_id);
      setValue("treatment_second_id", entity.treatment_second_id);
      setValue("two_anaerobic_treatments", entity.two_anaerobic_treatments.toString());
      setValue("effluent_degradable_unity", entity.effluent_degradable_unity.toString());
      setValue("effluent_degradable_after_unity", entity.effluent_degradable_after_unity.toString());

      handleChangeTreatmentFirst(entity.treatment_first_id)
      handleChangeTreatmentSecond(entity.treatment_second_id)

      setSecondTreatment(entity.two_anaerobic_treatments === 1)

      setValue("nitrogen_removed", formatPrice3(Number(entity.nitrogen_removed), false));
      setValue("amount_n2_nitrogen", formatPrice3(Number(entity.amount_n2_nitrogen), false));
      setValue("n2o_emission_factor", formatPrice3(Number(entity.n2o_emission_factor), false));
      setValue("effluent_degradable", formatPrice3(Number(entity.effluent_degradable), false));
      setValue("nitrogen_removed_after", formatPrice3(Number(entity.nitrogen_removed_after), false));
      setValue("amount_n2_nitrogen_after", formatPrice3(Number(entity.amount_n2_nitrogen_after), false));
      setValue("effluent_degradable_after", formatPrice3(Number(entity.effluent_degradable_after), false));
      setValue("generated_liquid_effluent", formatPrice3(Number(entity.generated_liquid_effluent), false));
      setValue("n2o_emission_factor_after", formatPrice3(Number(entity.n2o_emission_factor_after), false));
      setValue("ch4_amount_recovered_methane", formatPrice3(Number(entity.ch4_amount_recovered_methane), false));
      setValue("destination_recovered_biogas", formatPrice3(Number(entity.destination_recovered_biogas), false));
      setValue("generated_liquid_effluent_after", formatPrice3(Number(entity.generated_liquid_effluent_after), false));
      setValue("ch4_amount_recovered_methane_after", formatPrice3(Number(entity.ch4_amount_recovered_methane_after), false));
      setValue("destination_recovered_biogas_after", formatPrice3(Number(entity.destination_recovered_biogas_after), false));

      setScopeSelected(entity)

      setIgnoreFill(entity.ignore_fill)
    }else{
      setValue("two_anaerobic_treatments", "0")

      setScopeSelected({})

      setSecondTreatment(false)

      setIgnoreFill(false)
      setSecondTreatment(false)
    }

    setInventorySelected(inventory)

    setLoadingModal(false)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{2})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  const handleChangeValue = () => {
    let nitrogen_removed = getValues('nitrogen_removed')
    let amount_n2_nitrogen = getValues('amount_n2_nitrogen')
    let treatment_first_id = getValues('treatment_first_id')
    let treatment_second_id = getValues('treatment_first_id')
    let n2o_emission_factor = getValues('n2o_emission_factor')
    let effluent_degradable = getValues('effluent_degradable')
    let nitrogen_removed_after = getValues('nitrogen_removed_after')
    let two_anaerobic_treatments = getValues('two_anaerobic_treatments')
    let amount_n2_nitrogen_after = getValues('amount_n2_nitrogen_after')
    let effluent_degradable_after = getValues('effluent_degradable_after')
    let generated_liquid_effluent = getValues('generated_liquid_effluent')
    let n2o_emission_factor_after = getValues('n2o_emission_factor_after')
    let effluent_degradable_unity = getValues('effluent_degradable_unity')
    let ch4_amount_recovered_methane = getValues('ch4_amount_recovered_methane')
    let destination_recovered_biogas = getValues('destination_recovered_biogas')
    let generated_liquid_effluent_after = getValues('generated_liquid_effluent_after')
    let effluent_degradable_after_unity = getValues('effluent_degradable_after_unity') 
    let destination_recovered_biogas_after = getValues('destination_recovered_biogas_after')
    let ch4_amount_recovered_methane_after = getValues('ch4_amount_recovered_methane_after')

    treatment_first_id = treatment_first_id ? parseInt(treatment_first_id) : 0
    treatment_second_id = treatment_second_id ? parseInt(treatment_second_id) : 0
    effluent_degradable_unity = effluent_degradable_unity ? parseInt(effluent_degradable_unity) : 0
    effluent_degradable_after_unity = effluent_degradable_after_unity ? parseInt(effluent_degradable_after_unity) : 0

    nitrogen_removed = nitrogen_removed ? parseFloat(nitrogen_removed.replace(".", "").replace(",", ".")) : 0
    amount_n2_nitrogen = amount_n2_nitrogen ? parseFloat(amount_n2_nitrogen.replace(".", "").replace(",", ".")) : 0
    n2o_emission_factor = n2o_emission_factor ? parseFloat(n2o_emission_factor.replace(".", "").replace(",", ".")) : 0
    effluent_degradable = effluent_degradable ? parseFloat(effluent_degradable.replace(".", "").replace(",", ".")) : 0
    nitrogen_removed_after = nitrogen_removed_after ? parseFloat(nitrogen_removed_after.replace(".", "").replace(",", ".")) : 0
    amount_n2_nitrogen_after = amount_n2_nitrogen_after ? parseFloat(amount_n2_nitrogen_after.replace(".", "").replace(",", ".")) : 0
    effluent_degradable_after = effluent_degradable_after ? parseFloat(effluent_degradable_after.replace(".", "").replace(",", ".")) : 0
    generated_liquid_effluent = generated_liquid_effluent ? parseFloat(generated_liquid_effluent.replace(".", "").replace(",", ".")) : 0
    n2o_emission_factor_after = n2o_emission_factor_after ? parseFloat(n2o_emission_factor_after.replace(".", "").replace(",", ".")) : 0
    two_anaerobic_treatments = destination_recovered_biogas ? parseFloat(two_anaerobic_treatments.replace(".", "").replace(",", ".")) : 0
    ch4_amount_recovered_methane = ch4_amount_recovered_methane ? parseFloat(ch4_amount_recovered_methane.replace(".", "").replace(",", ".")) : 0
    destination_recovered_biogas = destination_recovered_biogas ? parseFloat(destination_recovered_biogas.replace(".", "").replace(",", ".")) : 0
    generated_liquid_effluent_after = generated_liquid_effluent_after ? parseFloat(generated_liquid_effluent_after.replace(".", "").replace(",", ".")) : 0
    destination_recovered_biogas_after = destination_recovered_biogas_after ? parseFloat(destination_recovered_biogas_after.replace(".", "").replace(",", ".")) : 0
    ch4_amount_recovered_methane_after = ch4_amount_recovered_methane_after ? parseFloat(ch4_amount_recovered_methane_after.replace(".", "").replace(",", ".")) : 0

    async function calculate() {
      const result = await scope1Service.calculateLiquidEffluents(
        nitrogen_removed, amount_n2_nitrogen, treatment_first_id, treatment_second_id, n2o_emission_factor, 
        effluent_degradable, nitrogen_removed_after, amount_n2_nitrogen_after, effluent_degradable_after, 
        generated_liquid_effluent, n2o_emission_factor_after, effluent_degradable_unity, two_anaerobic_treatments, 
        ch4_amount_recovered_methane, destination_recovered_biogas, generated_liquid_effluent_after, 
        effluent_degradable_after_unity, destination_recovered_biogas_after, ch4_amount_recovered_methane_after);

      if (result){
        setEmissionCh4(result.emission_ch4)
        setEmissionN2o(result.emission_n2o)
        setEmissionCo2(result.emission_co2e)
        setEmissionCo2Bio(result.emission_co2_bio)

        setChangeScope("change_scope_item_" + result.emission_ch4 + "_" + result.emission_n2o + "_" + 
            result.emission_co2e + "_" + result.emission_co2_bio)
      }
    }

    calculate()
  }

  const handleChangeTreatmentFirst = (e: any) => {
    async function retrieveTreatmentTypeById(id: any) {
        const result = await treatmentTypeService.getById(id);

        if (result){
          setValue("treatment_mcf_first", formatPrice3(result.mcf, false))

          setFirstFactor(result.mcf)

          handleChangeValue()
        }
    }

    if (e){
      retrieveTreatmentTypeById(e)
    }
  }

  const handleChangeTreatmentSecond = (e: any) => {
    async function retrieveTreatmentTypeById(id: any) {
        const result = await treatmentTypeService.getById(id);

        if (result){
          setValue("treatment_mcf_second", formatPrice3(result.mcf, false))

          setFirstFactorMethane(result.mcf)

          handleChangeValue()
        }
    }

    if (e){
      retrieveTreatmentTypeById(e)
    }
  }

  function handleDeleteFile (id: number) {
    async function deleteFile(id:number) {
      const result = await inventoryService.deleteFile(id);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'efluentes');

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

	const downloadFile = (filename:string) => {
    window.open(filename, "_blank")
  }

	const attachmentChangeHandler = (event:any) => {
    async function saveImage(base64?:string, filename?:string) {
      const image = {
        type: 1,
        name: 'efluentes_liquidos',
        file: base64,
        filename: filename,
        second_name: 'efluentes',
      };

      const result = await inventoryService.saveFile(
          inventorySelected.company.id, inventorySelected.year, image);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'efluentes');

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
	};

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row" }} >
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        {!isCommonUser && (
          <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle", height: 30, marginBottom: 10 }} >
              <input style={{ height: "20px", width: "20px", margin:"10px" }} 
                      type="checkbox" {...register("ignore_fill")} 
                      onChange={(event) => {
                        setIgnoreFill(event.target.checked)
                      }} />

              <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px", lineHeight: "19px", 
                          color: "#31363F", opacity: "0.8", marginTop: 10 }} >
                  Selecione aqui, caso não deseje reportar registros deste escopo para o inventário deste ano.
              </label>
          </div>
        )}

        {ignoreFill === true && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10}} >
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Informe abaixo uma justificativa para o não preenchimento do escopo selecionado
            </label>

            <textarea style={{width: '95%', height: 100}}
                  {...register("reason", { required: true })}/>
                        
            {errors.reason && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 230px)", 
            maxHeight: "calc(100vh - 230px)"}} >
          {ignoreFill === false && (
            <div style={{ display: "flex", flexDirection: "column", width: "98%", marginTop: 10, marginLeft: 10 }} >
              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo01 && passo01.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo01.label}
                  </span>
                )}

                {passo01 && passo01.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">{passo01.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    São aplicados, sequencialmente, dois tipos de tratamentos anaeróbicos ao efluente gerado?
                  </span>
                </div>

                <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5,  marginRight: 20}}>
                    <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                            {...register("two_anaerobic_treatments")}
                            onChange={(event) => {
                              setValue("two_anaerobic_treatments", event.target.value);
                              setSecondTreatment(true)
                              handleChangeValue()
                            }} />  
                    <span>Sim</span> 

                    <input type="radio" value="0" style={{width: 20, marginRight: 10}}
                            {...register("two_anaerobic_treatments")}
                            onChange={(event) => {
                              setValue("two_anaerobic_treatments", event.target.value);
                              setSecondTreatment(false)
                              handleChangeValue()
                            }} />  
                    <span>Não</span> 
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo02 && passo02.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo02.label}
                  </span>
                )}

                {passo02 && passo02.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">{passo02.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Quantidade de efluente líquido gerada no ano do inventário
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >
                  <input style={{width: '200px', height: 35, textAlign: "right"}}
                        {...register("generated_liquid_effluent")}
                        onChange={(event) => {
                            setValue("generated_liquid_effluent", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                          width: 60, borderBottom: "1px solid black"}}>
                    [m³/ano]
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo03 && passo03.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo03.label}
                  </span>
                )}

                {passo03 && passo03.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">{passo03.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Componente orgânico degradável do efluente
                  </span>
                </div>

                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '160px', height: 35, textAlign: "right"}}
                        {...register("effluent_degradable")}
                        onChange={(event) => {
                            setValue("effluent_degradable", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 0, 
                                            width: 100, borderBottom: "1px solid black"}}>
                    <select style={{ borderWidth: "0px", width: "100px", height: "25px"}}
                          {...register("effluent_degradable_unity")}
                          onChange={(event) => {
                            handleChangeValue()
                          }}>
                        <option value='1' label='[kgDQO/m³]'/>
                        <option value='2' label='[kgDBO/m³]'/>
                    </select>
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Quantidade de Nitrogênio no efluente gerado
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '200px', height: 35, textAlign: "right"}}
                        {...register("amount_n2_nitrogen")}
                        onChange={(event) => {
                            setValue("amount_n2_nitrogen", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                            width: 60, borderBottom: "1px solid black"}}>
                    [kgN/m³]
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Fator de emissão de N₂O pela descarga de efluente
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '160px', height: 35, textAlign: "right"}}
                        {...register("n2o_emission_factor")}
                        onChange={(event) => {
                            setValue("n2o_emission_factor", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                            width: 102, borderBottom: "1px solid black"}}>
                    [kgN₂O-N/kgN]
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Nitrogênio removido com o lodo no ano do inventário
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '195px', height: 35, textAlign: "right"}}
                        {...register("nitrogen_removed")}
                        onChange={(event) => {
                            setValue("nitrogen_removed", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                  width: 55, borderBottom: "1px solid black", paddingRight: 15}}>
                    [kgN/ano]
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo04 && passo04.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo04.label}
                  </span>
                )}

                {passo04 && passo04.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">{passo04.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Escolha o tipo de tratamento aplicado ao efluente
                  </span>
                </div>

                {treatmentTypes && (
                  <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                      borderBottomWidth: "1px solid black", borderRadius: " 2.5px", width: "275px", height: "40px"}}
                      {...register("treatment_first_id")} 
                      onChange={(event) => {
                        const { value } = event.target;

                        handleChangeTreatmentFirst(value)
                      }}>
                      {treatmentTypes && treatmentTypes.map((type: any) => {
                        return (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Fator de conversão de metano (MCF1)
                  </span>
                </div>

                <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                      {...register("treatment_mcf_first")}/>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                {passo05 && passo05.label && (
                  <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                    {passo05.label}
                  </span>
                )}

                {passo05 && passo05.guidelines && (
                  <div className="tooltip" style={{margin: 5}}>
                    <img src={InfoButtonMin} alt="information"/>
                    <span className="tooltiptext">{passo05.guidelines}</span>
                  </div>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between" }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    Quantidade de CH₄ recuperada do tratamento
                  </span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                  <input style={{width: '195px', height: 35, textAlign: "right"}}
                        {...register("ch4_amount_recovered_methane")}
                        onChange={(event) => {
                            setValue("ch4_amount_recovered_methane", currency(event));
                            handleChangeValue()
                        }}/>

                  <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                  width: 55, borderBottom: "1px solid black", paddingRight: 15}}>
                    [tCH₄/ano]
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                    justifyContent: "space-between", marginBottom: 10 }} >
                <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                  <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                    No ano do inventário, qual o destino do biogás recuperado? 
                  </span>
                </div>

                <select style={{ borderWidth: "0px", width: "275px", height: "25px"}}
                    {...register("destination_recovered_biogas")}
                    onChange={(event) => {
                      handleChangeValue()
                    }}>
                    <option value='1' label='Queima em flare'/>
                    <option value='2' label='Geração de energia'/>
                </select>
              </div>

              {secondTreatment === true && (
                <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                  Caso indicada no Passo 1 a existência de tratamento sequencial, insira os dados para o segundo tipo de tratamento anaeróbico aplicado ao efluente.
                </span>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo06 && passo06.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo06.label}
                    </span>
                  )}

                  {passo06 && passo06.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information"/>
                      <span className="tooltiptext">{passo06.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Quantidade de efluente líquido após a aplicação do primeiro tratamento
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '205px', height: 35, textAlign: "right"}}
                          {...register("generated_liquid_effluent_after")}
                          onChange={(event) => {
                              setValue("generated_liquid_effluent_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                    width: 55, borderBottom: "1px solid black"}}>
                      [m³/ano]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo07 && passo07.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo07.label}
                    </span>
                  )}

                  {passo07 && passo07.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information"/>
                      <span className="tooltiptext">{passo07.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Componente orgânico degradável do efluente
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("effluent_degradable_after")}
                          onChange={(event) => {
                              setValue("effluent_degradable_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 0, 
                                              width: 100, borderBottom: "1px solid black"}}>
                      <select style={{ borderWidth: "0px", width: "100px", height: "25px"}}
                          {...register("effluent_degradable_after_unity")}
                          onChange={(event) => {
                              handleChangeValue()
                          }}>
                          <option value='1' label='[kgDQO/m³]'/>
                          <option value='2' label='[kgDBO/m³]'/>
                      </select>
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Quantidade de nitrogênio no efluente gerado
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '203px', height: 35, textAlign: "right"}}
                          {...register("amount_n2_nitrogen_after")}
                          onChange={(event) => {
                              setValue("amount_n2_nitrogen_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                    width: 55, borderBottom: "1px solid black", paddingRight: 5}}>
                      [kgN/m³]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Fator de emissão de N₂O pela descarga de efluente
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '160px', height: 35, textAlign: "right"}}
                          {...register("n2o_emission_factor_after")}
                          onChange={(event) => {
                              setValue("n2o_emission_factor_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                    width: 102, borderBottom: "1px solid black"}}>
                      [kgN₂O-N/kgN]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Nitrogênio removido com o lodo no ano do inventário
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '195px', height: 35, textAlign: "right"}}
                          {...register("nitrogen_removed_after")}
                          onChange={(event) => {
                              setValue("nitrogen_removed_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                    width: 55, borderBottom: "1px solid black", paddingRight: 10}}>
                      [kgN/ano]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo08 && passo08.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo08.label}
                    </span>
                  )}

                  {passo08 && passo08.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information"/>
                      <span className="tooltiptext">{passo08.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between" }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Escolha o tipo de tratamento aplicado ao efluente
                    </span>
                  </div>

                  {treatmentTypes && (
                    <select style={{ borderTopWidth: "0px", borderLeftWidth: "0px", borderRightWidth: "0px", 
                        borderBottomWidth: "1px solid black", borderRadius: " 2.5px", width: "275px", height: "40px"}}
                        {...register("treatment_second_id")}
                        onChange={(event) => {
                          const { value } = event.target;

                          handleChangeTreatmentSecond(value)
                        }}>
                        {treatmentTypes && treatmentTypes.map((type: any) => {
                          return (
                            <option value={type.id} key={type.id}>
                              {type.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 10 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Fator de conversão de metano (MCF1)
                    </span>
                  </div>

                  <input readOnly style={{width: '270px', height: 35, textAlign: "right"}}
                        {...register("treatment_mcf_second")}/>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 5, marginRight: 30}} >
                  {passo09 && passo09.label && (
                    <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                      {passo09.label}
                    </span>
                  )}

                  {passo09 && passo09.guidelines && (
                    <div className="tooltip" style={{margin: 5}}>
                      <img src={InfoButtonMin} alt="information"/>
                      <span className="tooltiptext">{passo09.guidelines}</span>
                    </div>
                  )}
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between" }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      Quantidade de CH₄ recuperada do tratamento
                    </span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", margin: 0}} >

                    <input style={{width: '195px', height: 35, textAlign: "right"}}
                          {...register("ch4_amount_recovered_methane_after")}
                          onChange={(event) => {
                              setValue("ch4_amount_recovered_methane_after", currency(event));
                              handleChangeValue()
                          }}/>

                    <span style={{textAlign: "center", fontSize: 14, padding:5,  marginTop: 5, 
                                    width: 55, borderBottom: "1px solid black", paddingRight: 15}}>
                      [tCH₄/ano]
                    </span>
                  </div>
                </div>
              )}

              {secondTreatment === true && (
                <div style={{ display: "flex", flexDirection: "row", marginTop: 0, 
                      justifyContent: "space-between", marginBottom: 5 }} >
                  <div style={{ display: "flex", flexDirection: "column", marginTop: 5, marginRight: 30}} >
                    <span style={{textAlign: "start", fontSize: 14,  marginTop: 5}}>
                      No ano do inventário, qual o destino do biogás recuperado? 
                    </span>
                  </div>

                  <select style={{ borderWidth: "0px", width: "275px", height: "25px"}}
                      {...register("destination_recovered_biogas_after")}
                      onChange={(event) => {
                          handleChangeValue()
                      }}>
                      <option value='1' label='Queima em flare'/>
                      <option value='2' label='Geração de energia'/>
                  </select>
                </div>
              )}

              <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)", marginBottom: 10, marginTop: 20}} />

              <div style={{ display: "flex", flexDirection: "column", marginRight: 30, marginBottom: 10}} >
                <span style={{textAlign: "start", fontSize: 16,  marginTop: 5, fontWeight: "bold"}}>
                  Tabela 1 - Emissões Totais de Efluentes Líquidos
                </span>
              </div>

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "column", marginLeft: 0}} >
                  <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
                    <span style={{ margin: 10, width: "70%" }}>
                      Emissões de CH₄ por tratamento de efluentes [tCH₄/ano]:
                    </span>
                    <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                      {formatPrice3(emissionCh4, false)}
                    </span>
                  </div>

                  {emissionCh4 < 0 && (
                    <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                          fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                      O dado de metano recuperado é maior que o de metano gerado. Revise seus cálculos.
                    </div>
                  )}
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "column", marginLeft: 0}} >
                  <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
                    <span style={{ margin: 10, width: "70%" }}>
                      Emissões de N₂O por tratamento de efluentes [tN₂O/ano]
                    </span>
                    <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                      {formatPrice3(emissionN2o, false)}
                    </span>
                  </div>

                  {emissionN2o < 0 && (
                    <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                          fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                      O dado de remoção de nitrogênio como lodo é maior que a quantidade de nitrogênio no efluente gerado. Revise seus cálculos
                    </div>
                  )}
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões em CO₂e por tratamento de efluentes [tCO₂e/ano]
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionCo2, false)}
                  </span>
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "auto", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões em CO₂ biogênico por tratamento de efluentes [tCO₂/ano]
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionCo2Bio, false)}
                  </span>
                </div>
              )}

            </div>
          )}

          {!ignoreFill && (
            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleText level={2} title='Orientações gerais' />
        </div>

        <div style={{marginTop: 10, width: 550}}>
          {guidelines && (
            <span style={{fontSize: 12, textAlign: "justify"}}>
              {Parser(guidelines)}
            </span>
          )}
        </div>

      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  );
};
