import React, { useEffect, useState } from "react";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import useAuth from "../../../../../hooks/useAuth";
import inventoryService from "../../../../../services/inventoryService";
import { Container } from "./styles";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../../../../components/Button/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { formatPrice, formatPrice3, formatPrice5 } from "../../../../../utils/transform";
import scope1Service from "../../../../../services/scope1Service";
import { Modal } from "../../../../../components/Modal";
import Parser from 'html-react-parser';
import scopeService from "../../../../../services/scopeService";
import { toast } from "react-hot-toast";
import { Loading } from "../../../../../components/Loading";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";

export const EffluentsOthers = () => {
  const history = useHistory();
  
  const { isCommonUser } = useAuth()

  const [files, setFiles] = useState<any>([]);
  const [title, setTitle] = useState<string>('')
  const [loadingModal, setLoadingModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>();

  const [openModal, setOpenModal] = useState(false);
  const [ignoreFill, setIgnoreFill] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  const [inventorySelected, setInventorySelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const location = useLocation();

  const [scopes, setScopes] = useState<any>([]);
  const [changeScope, setChangeScope] = useState<string | undefined>();

  const [emissionCh4Item, setEmissionCh4Item] = useState<any>();
  const [emissionN2oItem, setEmissionN2oItem] = useState<any>();
  const [emissionCo2Item, setEmissionCo2Item] = useState<any>();
  const [emissionTco2eItem, setEmissionTco2eItem] = useState<any>();
  const [emissionCo2BioItem, setEmissionCo2BioItem] = useState<any>();

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id && item.year){
      getInventorysById(item.company_id, item.year, item.scope)
    }else{
      history.push({
        pathname: '/inventario/escopo-1',
      });
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  useEffect(() => {
    calculateTotals(scopes)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [scopes]);

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(1, 'efluentes_liquidos', 'relato_de_emissoes')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  function handleOpenInfo () {
    setOpenModal(!openModal)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const itens:any[] = scopes.map((item: any, index: number) => {
        return {
          source: item.source,
          type_of_waste: item.type_of_waste,
          emission_co2: item.emission_co2,
          emission_ch4: item.emission_ch4,
          emission_n2o: item.emission_n2o,
          emission_tco2e: item.emission_tco2e,
          emission_co2_bio: item.emission_co2_bio,
        };
      })

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        itens: itens,
      };

      const result = await scope1Service.saveLiquidEffluentsOther(
          inventorySelected.company.id, inventorySelected.year, scope);

      if (result && !result.error) {
        localStorage.setItem('LiquidEffluentsOther', JSON.stringify(scope));
        
        setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }

    setLoadingModal(false)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope:number) {
    setLoadingModal(true)

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope1Service.getLiquidEffluentsOther(company_id, year)
    
    const result = await inventoryService.getFiles(
      inventory.company.id, inventory.year, 1, 'efluentes_liquidos', 'relato_de_emissoes');

    setFiles(result.files)

    if (entity){
      setValue("reason", entity.reason)
      setValue("evidence", entity.evidence)
      setValue("ignore_fill", entity.ignore_fill)

      setScopeSelected(entity)

      setScopeValues(entity?.itens)
      calculateTotals(entity?.itens)

      setIgnoreFill(entity.ignore_fill)

      setChangeScope("load_scope_" + entity.id + "_scopes_" + entity?.itens.length)
    }else{
      setValue("two_anaerobic_treatments", "0")

      setScopeSelected({})

      setScopeValues([])
      calculateTotals([])

      setIgnoreFill(false)

      setChangeScope("load_scope_0_scopes_0")
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d)(\d{5})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  const handleNewItem = () => {
    const item = {
      source: undefined, 
      type_of_waste: undefined, 
      emission_co2: undefined, 
      emission_ch4: undefined, 
      emission_n2o: undefined, 
      emission_tco2e: undefined, 
      emission_co2_bio: undefined, 
    }

    const index = scopes.length

    const itens = scopes.concat(item)

    setChangeScope("new_scope_item_" + index)

    setScopeValues(itens)
    calculateTotals(itens)

    setValue("gases_cycle", [])
  }

  const setScopeValues = (itens:any, ignore_field?:string) => {
    let changeScope = "change_scope_item_"

    if (itens){
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`scope.${i}.source`, item.source)
        setValue(`scope.${i}.type_of_waste`, item.type_of_waste)
        
        if ('emission_co2' !== ignore_field){
          setValue(`scope.${i}.emission_co2`,formatPrice3(item.emission_co2, false))
        }
        
        if ('emission_ch4' !== ignore_field){
          setValue(`scope.${i}.emission_ch4`,formatPrice3(item.emission_ch4, false))
        }
        
        if ('emission_n2o' !== ignore_field){
          setValue(`scope.${i}.emission_n2o`,formatPrice3(item.emission_n2o, false))
        }
        
        if ('emission_tco2e' !== ignore_field){
          setValue(`scope.${i}.emission_tco2e`,formatPrice3(item.emission_tco2e, false))
        }
        
        if ('emission_co2_bio' !== ignore_field){
          setValue(`scope.${i}.emission_co2_bio`,formatPrice3(item.emission_co2_bio, false))
        }

        changeScope = changeScope + "_" + item.emission_co2 + "_" + 
            item.emission_ch4 + "_" + item.emission_n2o
      }
    }

    setScopes(itens)
    setChangeScope(changeScope)
  }

  const calculateTotals = (itens:any) => {
    let _emissionCo2 = 0
    let _emissionCh4 = 0
    let _emissionN2o = 0
    let _emissionTco2e = 0
    let _emissionCo2Bio = 0

    if (itens){
      for (const item of itens){
        if (item.emission_co2){
          _emissionCo2 = _emissionCo2 + item.emission_co2
        }
        if (item.emission_ch4){
          _emissionCh4 = _emissionCh4 + item.emission_ch4
        }
        if (item.emission_n2o){
          _emissionN2o = _emissionN2o + item.emission_n2o
        }
        if (item.emission_tco2e){
          _emissionTco2e = _emissionTco2e + item.emission_tco2e
        }
        if (item.emission_co2_bio){
          _emissionCo2Bio = _emissionCo2Bio + item.emission_co2_bio
        }
      }
    }

    setEmissionCo2Item(_emissionCo2)
    setEmissionCh4Item(_emissionCh4)
    setEmissionN2oItem(_emissionN2o)
    setEmissionTco2eItem(_emissionTco2e)
    setEmissionCo2BioItem(_emissionCo2Bio)

    setChangeScope("calculate_iten_" + _emissionCo2 + "_" + _emissionCh4 + "_" + 
      _emissionN2o + "_" + _emissionTco2e + "_" + _emissionCo2Bio)
  }

  const handleChangeValueItem = (index:number, ignore_field?:string) => {
    const emission_co2 = scopes[index].emission_co2
    const emission_ch4 = scopes[index].emission_ch4
    const emission_n2o = scopes[index].emission_n2o

    async function calculate() {
      const result = await scope1Service.calculateLiquidEffluentsOther(emission_co2, emission_ch4, emission_n2o);

      if (result){
        scopes[index].emission_tco2e = result.emission_tco2e

        setChangeScope("change_scope_item_" + index + "_" + emission_co2 + "_" + 
          emission_ch4 + "_" + emission_n2o)

        setScopeValues(scopes, ignore_field)
        calculateTotals(scopes)
      }
    }

    calculate()
  }

  function handleDelete (index: number) {
    const itens = []

    for (let i = 0; i < scopes?.length; i++) {
      if (i !== index){
        itens.push(scopes[i])
      }
    }

    setChangeScope("remove_scope_item_" + index)

    setValue("gases_cycle", [])

    setScopeValues(itens)
    calculateTotals(itens)
  }

  function handleDeleteFile (id: number) {
    async function deleteFile(id:number) {
      const result = await inventoryService.deleteFile(id);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'relato_de_emissoes');

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

	const downloadFile = (filename:string) => {
    window.open(filename, "_blank")
  }

	const attachmentChangeHandler = (event:any) => {
    async function saveImage(base64?:string, filename?:string) {
      const image = {
        type: 1,
        name: 'efluentes_liquidos',
        file: base64,
        filename: filename,
        second_name: 'relato_de_emissoes',
      };

      const result = await inventoryService.saveFile(
          inventorySelected.company.id, inventorySelected.year, image);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
            inventorySelected.company.id, inventorySelected.year, 1, 'efluentes_liquidos', 'relato_de_emissoes');

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
	};

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          {inventorySelected?.year && inventorySelected?.company && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
              <TitleText level={4} title={'Escopo 01 - ' + title}  />
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row" }} >
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        {!isCommonUser && (
          <div style={{ display: "flex", flexDirection: "row", verticalAlign: "middle", height: 30, marginBottom: 10 }} >
              <input style={{ height: "20px", width: "20px", margin:"10px" }} 
                      type="checkbox" {...register("ignore_fill")} 
                      onChange={(event) => {
                        setIgnoreFill(event.target.checked)
                      }} />

              <label style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px", lineHeight: "19px", 
                          color: "#31363F", opacity: "0.8", marginTop: 10 }} >
                  Selecione aqui, caso não deseje reportar registros deste escopo para o inventário deste ano.
              </label>
          </div>
        )}

        {ignoreFill === true && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10}} >
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Informe abaixo uma justificativa para o não preenchimento do escopo selecionado
            </label>

            <textarea style={{width: '95%', height: 100}}
                  {...register("reason", { required: true })}/>
                        
            {errors.reason && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "calc(100vh - 230px)", 
            maxHeight: "calc(100vh - 230px)"}} >
          {ignoreFill === false && (
            <div style={{ display: "flex", flexDirection: "column", width: "98%", marginTop: 10, marginLeft: 10 }} >

              <div style={{ overflow: "hidden auto", position: "relative", marginTop: 0,  height: "100%"}} >

                {ignoreFill === false && (
                  <div style={{ display: "flex", flexDirection: "column", width: "98%", marginTop: 0, marginLeft: 0 }} >
                    <div style={{ display: "flex", flexDirection: "row", margin: 0}} >
                      <div style={{ display: "flex", flexDirection: "row", margin: 0, width: "80%"}} >
                        <div style={{ display: "flex", flexDirection: "column", margin: 0}} >
                          <span
                            style={{
                              textAlign: 'start',
                              fontSize: 14,
                              marginTop: 25,
                              fontWeight: 'bold'
                            }}
                          >
                            Relato de emissões de GEE por tratamento de efluentes calculadas em outra ferramenta.
                          </span>
                          <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                            Clique, ao lado em novo registro, para adicionar nova linha
                          </span>
                        </div>
                      </div>

                      {!isCommonUser && (
                        <div style={{ textAlign: "right", width: "30%"}} >
                          <button type="button" style={{ width: "160px", height: "47px", background: "#ffffff",
                              color: "#31363F", borderColor: "#9E9E9E", borderRadius: "2.5px", cursor: "pointer",
                              borderStyle: "dashed", fontWeight: "bold",  borderWidth: "1px" }}
                              onClick={() => handleNewItem()} >
                            {`Novo registro`}
                          </button>
                        </div>
                      )}
                    </div>
      
                    <div style={{ overflow: "auto", position: "relative", marginTop: 0, 
                        width: "calc(100vw - 320px)", maxWidth: "calc(100vw - 320px)"}} >
      
                      {changeScope && scopes && scopes.length > 0 && (
                        <table style={{ maxWidth: "100%", width: "4000px", borderCollapse: "collapse"}} className="table-scope">
                          <thead>
                            <tr>
                              <th className="head-tr-item" style={{width: 200}}>
                                {"Registro da fonte"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}}>
                                {"Tipo de resíduo"}
                                <br/>
                                {"e tratamento"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}} >
                                {"Emissões de"}
                                <br/>
                                {"CO₂ (t)"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}} >
                                {"Emissões de"}
                                <br/>
                                {"CH₄ (t)"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}} >
                                {"Emissões de"}
                                <br/>
                                {"N₂O (t)"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}} >
                                {"Emissões de"}
                                <br/>
                                {"CO₂e (t)"}
                              </th>
                              <th className="head-tr-item" style={{width: 200}} >
                                {"Emissões de CO₂"}
                                <br/>
                                {"biogênico (t)"}
                              </th>
                              <th className="head-tr-item" style={{width: 50}} />
                            </tr>
                          </thead>
                          <tbody>
                            {scopes.map((scope: any, index: number) => {
                              return (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td >
                                        <input style={{ width: "95%" }} className="input-item"
                                          {...register(`scope.${index}.source`, { required: true })}
                                          onChange={(event) => {
                                              setValue(`scope.${index}.source`, event.target.value);
                                              scopes[index].source = event.target.value
                                          }}
                                        />
                                    </td>
                                    <td >
                                        <input style={{ width: "95%" }} className="input-item"
                                          {...register(`scope.${index}.type_of_waste`, { required: true })}
                                          onChange={(event) => {
                                              setValue(`scope.${index}.type_of_waste`, event.target.value);
                                              scopes[index].type_of_waste = event.target.value
                                          }}
                                        />
                                    </td>
                                    <td >
                                        <input style={{ width: "95%", textAlign: "right" }} className="input-item"
                                          {...register(`scope.${index}.emission_co2`)}
                                          onChange={(event) => {
                                            const formatValue =  event.target.value;
                                            const valueStr = formatValue.replace(".", "");
                                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0
                                            
                                            scopes[index].emission_co2 = value
      
                                            setValue(`scope.${index}.emission_co2`, formatValue);
      
                                            handleChangeValueItem(index, 'emission_co2')
                                          }}
                                        />
                                    </td>
                                    <td >
                                        <input style={{ width: "95%", textAlign: "right" }} className="input-item"
                                          {...register(`scope.${index}.emission_ch4`)}
                                          onChange={(event) => {
                                            const formatValue =  event.target.value;
                                            const valueStr = formatValue.replace(".", "");
                                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0
                                            
                                            scopes[index].emission_ch4 = value
      
                                            setValue(`scope.${index}.emission_ch4`, formatValue);
      
                                            handleChangeValueItem(index, 'emission_ch4')
                                          }}
                                        />
                                    </td>
                                    <td >
                                        <input style={{ width: "95%", textAlign: "right" }} className="input-item"
                                          {...register(`scope.${index}.emission_n2o`)}
                                          onChange={(event) => {
                                            const formatValue =  event.target.value;
                                            const valueStr = formatValue.replace(".", "");
                                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0
                                            
                                            scopes[index].emission_n2o = value
      
                                            setValue(`scope.${index}.emission_n2o`, formatValue);
      
                                            handleChangeValueItem(index, 'emission_n2o')
                                          }}
                                        />
                                    </td>
                                    <td style={{width: 100}}>
                                      <span style={{ marginLeft: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                        {formatPrice3(scope.emission_tco2e, false)}
                                      </span>
                                    </td>
                                    <td >
                                        <input style={{ width: "95%", textAlign: "right" }} className="input-item"
                                          {...register(`scope.${index}.emission_co2_bio`)}
                                          onChange={(event) => {
                                            const formatValue =  event.target.value;
                                            const valueStr = formatValue.replace(".", "");
                                            const value = valueStr ? parseFloat(valueStr.replace(",", ".")) : 0
                                            
                                            scopes[index].emission_co2_bio = value
      
                                            setValue(`scope.${index}.emission_co2_bio`, formatValue);
      
                                            handleChangeValueItem(index, 'emission_co2_bio')
                                          }}
                                        />
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <div onClick={() => handleDelete(index)} style={{cursor: "pointer", textAlign: "center", width: 70}}>
                                        <img src={TrashIcon} alt="lixeirinha"/>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            <tr style={{fontWeight: "bold"}}>
                              <td colSpan={2} className="head-tr-item" style={{ paddingRight: 5, textAlign: "right" }}>
                                {"total"}
                              </td>
                              <td className="head-tr-item" >
                                <span style={{ marginRight: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                  {formatPrice5(emissionCo2Item, false)}
                                </span>
                              </td>
                              <td className="head-tr-item" >
                                <span style={{ marginRight: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                  {formatPrice5(emissionCh4Item, false)}
                                </span>
                              </td>
                              <td className="head-tr-item" >
                                <span style={{ marginRight: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                  {formatPrice5(emissionN2oItem, false)}
                                </span>
                              </td>
                              <td className="head-tr-item" >
                                <span style={{ marginRight: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                  {formatPrice5(emissionTco2eItem, false)}
                                </span>
                              </td>
                              <td className="head-tr-item" >
                                <span style={{ marginRight: 5,  width: "95%", fontSize: 12, textAlign: "right", display: "inline-block" }}>
                                  {formatPrice5(emissionCo2BioItem, false)}
                                </span>
                              </td>
                              <td colSpan={5} className="head-tr-item" />
                            </tr>
                          </tfoot>
                        </table>
                      )}
      
                      {scopes && scopes.length === 0 && (
                        <div style={{ borderStyle: "dashed", margin: 15, borderWidth: "1px", 
                                      borderColor: "#9E9E9E", height: "60px", display: "flex", 
                                      flexDirection: "column", width: "95%", verticalAlign: "middle", 
                                      textAlign: "center"}} >
                          <span style={{ textDecoration: "underline", paddingTop: 20 }}>
                            Nenhum registro foi informado, clique em 'novo' para incluir seu registro.
                          </span>
                        </div>
                      )}
      
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: 10 }} >
                        {errors && errors.scope && (
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: 10 }} >
                            <span className='requiredLabel' style={{ fontSize: 14, marginTop: 10 }}>
                              * Registro da fonte e Tipo de resíduo e tratamento são obrigatórios
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)", marginBottom: 20, marginTop: 20}} />

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "40px", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões totais em CO₂ equivalente (toneladas métricas):
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionTco2eItem, false)}
                  </span>
                </div>
              )}

              {changeScope && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "40px", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões totais de CO₂ biogênico (toneladas métricas):
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionCo2BioItem, false)}
                  </span>
                </div>
              )}

            </div>
          )}


          {!ignoreFill && (
            <div className="column" style={{ margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                className="column"
                style={{
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div className="column" style={{ margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal isOpen={openModal} handleModalVisible={() => handleOpenInfo()} isPayOrTypeClient={true}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleText level={2} title='Orientações gerais' />
        </div>

        <div style={{marginTop: 10, width: 550}}>
          {guidelines && (
            <span style={{fontSize: 12, textAlign: "justify"}}>
              {Parser(guidelines)}
            </span>
          )}
        </div>

      </Modal>

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  );
};
