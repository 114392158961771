import Parser from 'html-react-parser'
import Select from 'react-select'
import { useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import useAuth from '../../../../../hooks/useAuth'
import { Container } from './styles'
import { formatPrice17, formatPrice3 } from '../../../../../utils/transform'
import inventoryService from '../../../../../services/inventoryService'
import scope3Service from '../../../../../services/scope3Service'
import vehicleFuelService from '../../../../../services/vehicleFuelService'
import scopeService from '../../../../../services/scopeService'
import InfoButtonMin from '../../../../../assets/icons/info-button.svg'
import TrashIcon from '../../../../../assets/icons/trash.svg'
import { Button } from '../../../../../components/Button/styles'
import { Modal } from '../../../../../components/Modal'
import { GoBack } from '../../../../../components/GoBack'
import { TitleText } from '../../../../../components/TitleText'
import ReportRecordsCheckbox from '../../../components/ReportRecordsCheckbox'
import ScopeEvidenceAttachments from '../../../components/ScopeEvidenceAttachments'
import { Uninformed } from '../../../../../components/Uninformed'
import { toast } from 'react-hot-toast'
import { Loading } from '../../../../../components/Loading'
import airportService from '../../../../../services/airportService'
import { ModalImportData } from '../../../../../components/ModalImportData'


// SÓ JESUS NA CAUSA DESSE CODIGO!

export const TduAirTransport = () => {
  const history = useHistory()

  const [files, setFiles] = useState<any>([])
  const [openModal, setOpenModal] = useState(false)
  const [ignoreFill, setIgnoreFill] = useState(true)
  const [title, setTitle] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({})
  const [importErrors, setImportErrors] = useState<any>([])
  const [openModalError, setOpenModalError] = useState(false)
  const [guidelines, setGuidelines] = useState<string | undefined>()
  const [inventorySelected, setInventorySelected] = useState<any>({})
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>()
  const location = useLocation()

  const [openModalAeroporto, setOpenModalAeroporto] = useState(false)
  const [openModalAeroKm, setOpenModalAeroKm] = useState(false)

  const [fuels, setFuels] = useState<any>([])
  const [airFuel, setAirFuel] = useState<any>([])
  const [airRoute, setAirRoute] = useState<any>([])
  const [loadingModal, setLoadingModal] = useState(false);
  const [emissionCo2AirFuel, setEmissionCo2AirFuel] = useState<any>()
  const [emissionCh4AirFuel, setEmissionCh4AirFuel] = useState<any>()
  const [emissionN2oAirFuel, setEmissionN2oAirFuel] = useState<any>()
  const [emissionCo2AirRoute, setEmissionCo2AirRoute] = useState<any>()
  const [emissionCh4AirRoute, setEmissionCh4AirRoute] = useState<any>()
  const [emissionN2oAirRoute, setEmissionN2oAirRoute] = useState<any>()
  const [scopeAirFuel, setScopeAirFuel] = useState<string | undefined>()
  const [scopeAirRoute, setScopeAirRoute] = useState<string | undefined>()
  const [emissionTco2eAirFuel, setEmissionTco2eAirFuel] = useState<any>()
  const [emissionTco2eAirRoute, setEmissionTco2eAirRoute] = useState<any>()

  const { isCommonUser } = useAuth()

  useEffect(() => {
    const item: any = location?.state

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope)
    } else {
      history.push({
        pathname: '/inventario/escopo-3/transp-distribuicao-upstream'
      })
    }

    getGuidelines()
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location])

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(3, 'transp_distribuicao_upstream', 'transporte_aereo')

    setTitle(item.label)
    setGuidelines(item.guidelines)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors }
  } = useForm<any>()

  const handleChangeFuel = (index: number, ignore_field?: string) => {
    const vehicle_fuel_id = airFuel[index].vehicle_fuel_id
    const consumption_yearly = airFuel[index].consumption_yearly ? parseFloat(airFuel[index].consumption_yearly) : 0

    async function calculate() {
      const result = await scope3Service.calculateTransportDistributionUpstreamAirFuel(
        vehicle_fuel_id,
        consumption_yearly
      )

      if (result) {
        airFuel[index].factor_co2_kg = parseFloat(result.factor_co2_kg)
        airFuel[index].factor_ch4_kg = parseFloat(result.factor_ch4_kg)
        airFuel[index].factor_n2o_kg = parseFloat(result.factor_n2o_kg)
        airFuel[index].emission_co2 = parseFloat(result.emission_co2)
        airFuel[index].emission_ch4 = parseFloat(result.emission_ch4)
        airFuel[index].emission_n2o = parseFloat(result.emission_n2o)
        airFuel[index].emission_tco2e = parseFloat(result.emission_tco2e)

        setScopeAirFuel(
          'change_scope_item_' + index + '_' + vehicle_fuel_id + '_' + consumption_yearly
        )

        setAirFuelValues(airFuel, ignore_field)
        calculateAirFuel(airFuel)
      }
    }

    calculate()
  }

  function handleDeleteFuel(index: number) {
    const itens = []

    for (let i = 0; i < airFuel?.length; i++) {
      if (i !== index) {
        itens.push(airFuel[i])
      }
    }

    setScopeAirFuel('remove_scope_item_' + index)

    setValue('fuel_private_vehicle', [])

    setAirFuelValues(itens)
    calculateAirFuel(itens)
  }

  function handleOpenInfo() {
    setOpenModal(!openModal)
  }

  const setAirFuelValues = (itens: any, ignore_field?: string) => {
    let scopeAir = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        setValue(`airFuel.${i}.source`, item.source)

        setValue(`airFuel.${i}.description`, item.description)

        setValue(`airFuel.${i}.vehicle_fuel_id`, item.vehicle_fuel_id)
        setValue(`airFuel.${i}.vehicle_fuel_name`, item.vehicle_fuel_name)

        setValue(`airFuel.${i}.fuel_id`, item.fuel_id)
        setValue(`airFuel.${i}.fuel_name`, item.fuel_name)

        if ('factor_co2_kg' !== ignore_field) {
          setValue(`airFuel.${i}.factor_co2_kg`,  item.factor_co2_kg)
        }
        
        if ('factor_ch4_kg' !== ignore_field) {
          setValue(`airFuel.${i}.factor_ch4_kg`,  item.factor_ch4_kg)
        }
        
        if ('factor_n2o_kg' !== ignore_field) {
          setValue(`airFuel.${i}.factor_n2o_kg`,  item.factor_n2o_kg)
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`airFuel.${i}.emission_co2`,   item.emission_co2)
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`airFuel.${i}.emission_ch4`,   item.emission_ch4)
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`airFuel.${i}.emission_n2o`,   item.emission_n2o)
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`airFuel.${i}.emission_tco2e`, item.emission_tco2e)
        }

        if ('consumption_yearly' !== ignore_field) {
          setValue(`airFuel.${i}.consumption_yearly`, item.consumption_yearly)
        }

        scopeAir = scopeAir + '_'  + item.vehicle_fuel_id + '_' + item.consumption_yearly
      }
    }

    setAirFuel(itens)
    setScopeAirFuel(scopeAir)
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{5})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const handleChangeCarFleetFuel = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await vehicleFuelService.getById(id)

      if (result) {
        const item = {
          vehicle_fuel_id: result.id,
          vehicle_fuel_name: result.name,
          fuel_id: result.fuel ? result.fuel.id : undefined,
          fuel_name: result.fuel ? result.fuel.name : undefined,
          bio_fuel_id: result.bioFuel ? result.bioFuel.id : undefined,
          bio_fuel_name: result.bioFuel ? result.bioFuel.name : undefined,
          only_view_yearly: false
        }

        const index = airFuel.length

        const itens = airFuel.concat(item)

        setScopeAirFuel('new_scope_item_' + index)
        setScopeAirRoute('new_scope_item_' + index)

        setAirFuelValues(itens)
        calculateAirFuel(itens)
        
      }
    }

    if (e) {
      retrieveServiceById(e.value)
    }

    setValue('fuel_private_vehicle', [])
  }

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true)

    const fuels = await vehicleFuelService.getByParams({limit: 9000, page: 1, type: 'air'})

    setFuels(
      fuels?.itens.map((fuel: any) => {
        return {
          label: fuel.name,
          value: fuel.id
        }
      })
    )

    const inventory = await inventoryService.getById(company_id, year)
    const entity = await scope3Service.getTransportDistributionUpstreamAir(
      company_id,
      year
    )
    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      'transp_distribuicao_upstream',
      'transporte_aereo'
    )

    setFiles(result.files)

    if (entity) {
      setValue('reason', entity.reason)
      setValue('evidence', entity.evidence)
      setValue('ignore_fill', entity.ignore_fill)

      setIgnoreFill(entity.ignore_fill)

      setScopeSelected(entity)

      setAirFuelValues(entity?.fuels)
      calculateAirFuel(entity?.fuels)

      setAirRouteValues(entity?.routes)
      calculateAirRoute(entity?.routes)

      setScopeAirFuel('load_scope_' + entity.id + '_scopes_' + entity?.fuels.length)
      setScopeAirRoute('load_scope_' + entity.id + '_scopes_' + entity?.routes.length)
    } else {
      setScopeSelected({})

      setIgnoreFill(false)

      setAirFuelValues([])
      calculateAirFuel([])

      setAirRouteValues([])
      calculateAirRoute([])

      setScopeAirFuel('load_scope_0_scopes_0')
      setScopeAirRoute('load_scope_0_scopes_0')
    }

    setInventorySelected(inventory)
    setLoadingModal(false)
  }

  const calculateAirFuel = (itens: any) => {
    let emissionCo2Air = 0
    let emissionCh4Air = 0
    let emissionN2oAir = 0
    let emissionTco2eAir = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Air = emissionCo2Air + item.emission_co2
        }
        if (item.emission_ch4) {
          emissionCh4Air = emissionCh4Air + item.emission_ch4
        }
        if (item.emission_n2o) {
          emissionN2oAir = emissionN2oAir + item.emission_n2o
        }
        if (item.emission_tco2e) {
          emissionTco2eAir = emissionTco2eAir + item.emission_tco2e
        }
      }
    }

    setEmissionCo2AirFuel(emissionCo2Air)
    setEmissionCh4AirFuel(emissionCh4Air)
    setEmissionN2oAirFuel(emissionN2oAir)
    setEmissionTco2eAirFuel(emissionTco2eAir)

    setScopeAirFuel(
      'calculate_iten_' +
        emissionCo2Air +
        '_' +
        emissionCh4Air +
        '_' +
        emissionN2oAir +
        '_' +
        emissionTco2eAir
    )
  }

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true)

    if (formData) {
      const routeItens: any[] = airRoute.map((item: any, index: number) => {
        return {
          origin: item.origin,
          destiny: item.destiny,
          register: item.register,
          origin_valid_airport: item.origin_valid_airport,
          destiny_valid_airport: item.destiny_valid_airport,
          factor_kg_co2_l: item.factor_kg_co2_l ? parseFloat(item.factor_kg_co2_l) : '0',
          factor_kg_ch4_l: item.factor_kg_ch4_l ? parseFloat(item.factor_kg_ch4_l) : '0',
          factor_kg_n2o_l: item.factor_kg_n2o_l ? parseFloat(item.factor_kg_n2o_l) : '0',
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : '0',
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4) : '0',
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o) : '0',
          distance_mass: item.distance_mass ? parseFloat(item.distance_mass) : '0',
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e) : '0',
          transported_volume: item.transported_volume,
          stretch_distance: item.stretch_distance ? parseFloat(item.stretch_distance) : '0'
        }
      })

      const fuelItens: any[] = airFuel.map((item: any, index: number) => {
        return {
          source: item.source,
          description: item.description,
          vehicle_fuel_id: item.vehicle_fuel_id,
          consumption_yearly: item.consumption_yearly ? parseFloat(item.consumption_yearly) : 0,
          factor_co2_kg: item.factor_co2_kg ? parseFloat(item.factor_co2_kg) : 0,
          factor_ch4_kg: item.factor_ch4_kg ? parseFloat(item.factor_ch4_kg) : 0,
          factor_n2o_kg: item.factor_n2o_kg ? parseFloat(item.factor_n2o_kg) : 0,
          emission_ch4: item.emission_ch4 ? parseFloat(item.emission_ch4) : 0,
          emission_n2o: item.emission_n2o ? parseFloat(item.emission_n2o) : 0,
          emission_co2: item.emission_co2 ? parseFloat(item.emission_co2) : 0,
          emission_tco2e: item.emission_tco2e ? parseFloat(item.emission_tco2e) : 0,

        }
      })

      const scope = {
        status: 2,
        fuels: fuelItens,
        routes: routeItens,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        reason: formData.ignore_fill ? formData.reason : ''
      }
      

      const result = await scope3Service.saveTransportDistributionUpstreamAir(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      )
      
      if (result && !result.error) {
        localStorage.setItem('TransportDistributionUpstreamAir', JSON.stringify(scope));

        setErrorApi(undefined)

        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error)
      }
    }

    setLoadingModal(false)
  }

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id)

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'transp_distribuicao_upstream',
          'transporte_aereo'
        )

        setFiles(result.files)
      }
    }

    deleteFile(id)
  }

  const downloadFile = (filename: string) => {
    window.open(filename, '_blank')
  }

  const attachmentChangeHandler = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: 'transp_distribuicao_upstream',
        file: base64,
        filename: filename,
        second_name: 'transporte_aereo'
      }

      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      )

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          'transp_distribuicao_upstream',
          'transporte_aereo'
        )

        setFiles(result.files)
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader()
  
      reader.readAsDataURL(file)
  
      reader.onload = () => {
        const base64 = reader.result
  
        saveImage(base64?.toString(), file.name)
      }
    }
  }

  const handleImportRoutes = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        
        setLoadingModal(true)

        const airports = await airportService.getByParams({ limit: 999999, page: 1 });

        const errors:any[] = []
        const itens = airRoute

        for (let i = 1; i < data?.length; i++) {
          const register = data[i][0]
          const origin = data[i][1]
          const destiny = data[i][11]
          const transported_volume = data[i][21]

          if (origin && destiny){
            let originObj:any = undefined
            let destinyObj:any = undefined
  
            for (const t of airports?.itens) {
              if (t.name === origin){
                originObj = t
                break;
              }
            }
  
            for (const t of airports?.itens) {
              if (t.name === destiny){
                destinyObj = t
                break;
              }
            }
  
            if (originObj && destinyObj){
              const scope = { register: register, origin: origin, destiny: destiny, transported_volume: transported_volume, 
                emission_co2: 0, emission_ch4: 0,  emission_n2o: 0, emission_tco2e: 0, distance_mass: 0, 
                factor_kg_co2_l: 0, factor_kg_ch4_l: 0, factor_kg_n2o_l: 0, stretch_distance: 0, origin_valid_airport: false, 
                destiny_valid_airport: false, 
              }

              const result = await scope3Service.calculateTransportDistributionUpstreamAirRoute(
                scope.origin,
                scope.destiny,
                scope.transported_volume
              )
              if (result) {
                scope.origin_valid_airport = result.origin_valid_airport
                scope.destiny_valid_airport = result.destiny_valid_airport
                scope.transported_volume = parseFloat(scope.transported_volume)
                scope.stretch_distance = parseFloat(result.stretch_distance)
                scope.distance_mass = parseFloat(result.distance_mass)
                scope.factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
                scope.factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
                scope.factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)
                scope.emission_co2 = parseFloat(result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)
                
                itens.push(scope)
              }
            }else if (!originObj){
              errors.push({id: i, label: originObj})
            }else if (!destinyObj){
              errors.push({id: i, label: origin})
            }
          }
        }

        setScopeAirRoute(`import_scope_data_${data?.length}`);
  
        setAirRouteValues(itens)
        calculateAirRoute(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleImportConsuption = (type?:number, data?:any[]) => {
    async function importData() {
      if (data){
        setLoadingModal(true)
        
        const errors:any[] = []
        const itens = airFuel

        for (let i = 1; i < data?.length; i++) {
          
          const vFuel = data[i][2]
          
          if (vFuel) {

            let obj: any = undefined
            
            for (const t of fuels) {
              if (t.label === vFuel) {
                obj = t
                break;
              }
            }
            if (obj) {
              const fuelObj = await vehicleFuelService.getById(obj.value)
              if (fuelObj) {

                const fuel = fuelObj.fuel
                const bioFuel = fuelObj.bioFuel

                const source = data[i][0]
                const description = data[i][1]
                const consumption_yearly = data[i][3]

                const scope = {source: source, description: description, consumption_yearly: consumption_yearly,
                  vehicle_fuel_id: obj.value, vehicle_fuel_name: obj.label, fuel_id: fuel ? fuel.id : undefined, fuel_name: fuel ? fuel.name : undefined,
                  bio_fuel_id: bioFuel ? bioFuel.id : undefined, bio_fuel_name: bioFuel ? bioFuel.name : undefined,
                  factor_kg_co2_l: 0, factor_kg_ch4_l: 0, factor_kg_n2o_l: 0, emission_co2: 0, emission_ch4: 0, emission_n2o: 0, emission_tco2e: 0 
                }

                const result = await scope3Service.calculateTransportDistributionUpstreamAirFuel(
                  scope.vehicle_fuel_id,
                  scope.consumption_yearly
              )
                
              if (result) {
                scope.consumption_yearly = parseFloat(scope.consumption_yearly)
                scope.factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
                scope.factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
                scope.factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)
                scope.emission_co2 = (result.emission_co2)
                scope.emission_ch4 = parseFloat(result.emission_ch4)
                scope.emission_n2o = parseFloat(result.emission_n2o)
                scope.emission_tco2e = parseFloat(result.emission_tco2e)

                itens.push(scope)
                  }
                } else {
                  errors.push({ id: i, label: vFuel })
                }
              } else {
                errors.push({ id: i, label: vFuel })
              }
            }
          }
        setScopeAirFuel(`import_scope_data_${data?.length}`);
  
        setAirFuelValues(itens)
        calculateAirFuel(itens)

        if (errors && errors.length > 0){
          handleOpenModalError(errors)
        }

        setLoadingModal(false)
      }
    }

    if (data){
      importData()
    }
  }

  const handleNewRoute = () => {
    const item = {
      origin: undefined,
      destiny: undefined,
      transported_volume: undefined
    }

    const index = airRoute.length

    const itens = airRoute.concat(item)

    setScopeAirRoute('new_scope_item_' + index)

    setAirRouteValues(itens)
    calculateAirRoute(itens)
  }

  const calculateAirRoute = (itens: any) => {
    let emissionCo2Air = 0
    let emissionCh4Air = 0
    let emissionN2oAir = 0
    let emissionTco2eAir = 0

    if (itens) {
      for (const item of itens) {
        if (item.emission_co2) {
          emissionCo2Air = emissionCo2Air + Number(item.emission_co2)
        }
        if (item.emission_ch4) {
          emissionCh4Air = emissionCh4Air + Number(item.emission_ch4)
        }
        if (item.emission_n2o) {
          emissionN2oAir = emissionN2oAir + Number(item.emission_n2o)
        }
        if (item.emission_tco2e) {
          emissionTco2eAir = emissionTco2eAir + Number(item.emission_tco2e)
        }
      }
    }

    setEmissionCo2AirRoute(emissionCo2Air)
    setEmissionCh4AirRoute(emissionCh4Air)
    setEmissionN2oAirRoute(emissionN2oAir)
    setEmissionTco2eAirRoute(emissionTco2eAir)

    setScopeAirRoute(
      'calculate_iten_' +
        emissionCo2Air +
        '_' +
        emissionCh4Air +
        '_' +
        emissionN2oAir +
        '_' +
        emissionTco2eAir
    )
  }

  const setAirRouteValues = (itens: any, ignore_field?: string) => {
    let scope = 'change_scope_item_'

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i]

        if ('origin' !== ignore_field) {
          setValue(`airRoute.${i}.origin`, item.origin)
        }

        if ('destiny' !== ignore_field) {
          setValue(`airRoute.${i}.destiny`, item.destiny)
        }

        if ('register' !== ignore_field) {
          setValue(`airRoute.${i}.register`, item.register)
        }

        if ('transported_volume' !== ignore_field) {
          setValue(`airRoute.${i}.transported_volume`, formatPrice3(Number(item.transported_volume), false))
        }

        if ('factor_kg_co2_l' !== ignore_field) {
          setValue(`airRoute.${i}.factor_kg_co2_l`, formatPrice3(Number(item.factor_kg_co2_l), false))
        }

        if ('factor_kg_ch4_l' !== ignore_field) {
          setValue(`airRoute.${i}.factor_kg_ch4_l`, formatPrice3(Number(item.factor_kg_ch4_l), false))
        }

        if ('factor_kg_n2o_l' !== ignore_field) {
          setValue(`airRoute.${i}.factor_kg_n2o_l`, formatPrice3(Number(item.factor_kg_n2o_l), false))
        }

        if ('emission_co2' !== ignore_field) {
          setValue(`airRoute.${i}.emission_co2`, formatPrice3(Number(item.emission_co2), false))
        }

        if ('emission_ch4' !== ignore_field) {
          setValue(`airRoute.${i}.emission_ch4`, formatPrice3(Number(item.emission_ch4), false))
        }

        if ('emission_n2o' !== ignore_field) {
          setValue(`airRoute.${i}.emission_n2o`, formatPrice3(Number(item.emission_n2o), false))
        }

        if ('emission_tco2e' !== ignore_field) {
          setValue(`airRoute.${i}.emission_tco2e`, formatPrice3(Number(item.emission_tco2e), false))
        }

        if ('distance_mass' !== ignore_field) {
          setValue(`airRoute.${i}.distance_mass`, formatPrice3(Number(item.distance_mass), false))
        }

        if ('stretch_distance' !== ignore_field) {
          setValue(`airRoute.${i}.stretch_distance`, formatPrice3(Number(item.stretch_distance), false))
        }

        if ('origin_valid_airport' !== ignore_field) {
          setValue(`airRoute.${i}.origin_valid_airport`, item.origin_valid_airport)
        }

        if ('destiny_valid_airport' !== ignore_field) {
          setValue(`airRoute.${i}.destiny_valid_airport`, item.destiny_valid_airport)
        }

        scope =
          scope + '_' + item.origin + '_' + item.destiny + '_' + item.transported_volume
      }
    }

    setAirRoute(itens)
    setScopeAirRoute(scope)
  }

  const handleChangeRoute = (index: number, ignore_field?: string) => {
    const origin = airRoute[index].origin
    const destiny = airRoute[index].destiny
    const transported_volume = airRoute[index].transported_volume ? parseFloat(airRoute[index].transported_volume) : 0

    async function calculate() {
      const result = await scope3Service.calculateTransportDistributionUpstreamAirRoute(
        origin,
        destiny,
        transported_volume
      )

      if (result) {
        airRoute[index].factor_kg_co2_l = parseFloat(result.factor_kg_co2_l)
        airRoute[index].factor_kg_ch4_l = parseFloat(result.factor_kg_ch4_l)
        airRoute[index].factor_kg_n2o_l = parseFloat(result.factor_kg_n2o_l)
        airRoute[index].emission_co2 = parseFloat(result.emission_co2)
        airRoute[index].emission_ch4 = parseFloat(result.emission_ch4)
        airRoute[index].emission_n2o = parseFloat(result.emission_n2o)
        airRoute[index].distance_mass = parseFloat(result.distance_mass)
        airRoute[index].transported_volume = parseFloat(result.transported_volume)
        airRoute[index].emission_tco2e = parseFloat(result.emission_tco2e)
        airRoute[index].stretch_distance = parseFloat(result.stretch_distance)
        airRoute[index].origin_valid_airport = result.origin_valid_airport
        airRoute[index].destiny_valid_airport = result.destiny_valid_airport

        setScopeAirRoute('change_scope_item_' + index + '_' + origin + '_' + destiny + '_' + transported_volume )

        setAirRouteValues(airRoute, ignore_field)
        calculateAirRoute(airRoute)
      }
    }

    calculate()
  }

  function handleDeleteRoute(index: number) {
    const itens = []

    for (let i = 0; i < airRoute?.length; i++) {
      if (i !== index) {
        itens.push(airRoute[i])
      }
    }

    setScopeAirRoute('remove_scope_item_' + index)

    setAirRouteValues(itens)
    calculateAirRoute(itens)
  }

  const handleOpenModalOrigemDestino = () => {
    setOpenModalAeroporto(!openModalAeroporto)
  }

  const handleOpenModalError = (data?:any) => {
    setImportErrors(data)
    setOpenModalError(!openModalError)
  }

  const handleOpenModalConsumoCombustivel = () => {
    setOpenModalAeroKm(!openModalAeroKm)
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 25,
            justifyContent: 'space-between',
            marginBottom: 20
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: 'auto' }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        <div style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }} />

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        {ignoreFill === true && (
          <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: '16px',
                lineHeight: '19px',
                color: '#31363F',
                opacity: '0.8',
                fontWeight: 'bold'
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do escopo
              selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: '95%', height: 100 }}
              {...register('reason', { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        <div
          style={{
            overflow: 'hidden auto',
            position: 'relative',
            marginTop: 0,
            height: 'calc(100vh - 230px)',
            maxHeight: 'calc(100vh - 230px)'
          }}
        >
          {ignoreFill === false && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '98%',
                marginTop: 10,
                marginLeft: 10
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 0,
                    width: '80%'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', margin: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 5,
                        marginRight: 0
                      }}
                    >
                      <span
                        style={{
                          textAlign: 'start',
                          fontSize: 14,
                          marginTop: 5,
                          fontWeight: 'bold'
                        }}
                      >
                        Opção 1 -  Cálculo de emissões de acordo com aeroportos de origem e destino e carga fracionada transportada no ano inventariado
                      </span>
                      <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5}} >
                        Clique, ao lado em novo registro, para adicionar nova linha
                      </span>
                    </div>
                  </div>
                </div>

                <div style={{ textAlign: 'right', width: '30%', marginBottom: 10 }}>
                  {!isCommonUser && (
                    <button
                      type="button"
                      style={{
                        width: '120px',
                        height: '47px',
                        background: '#ffffff',
                        color: '#31363F',
                        borderColor: '#9E9E9E',
                        borderRadius: '2.5px',
                        cursor: 'pointer',
                        borderStyle: 'dashed',
                        fontWeight: 'bold',
                        borderWidth: '1px'
                      }}
                      onClick={() => handleNewRoute()}
                    >
                      {`Novo registro`}
                    </button>
                  )}

                  {!isCommonUser && (
                    <button
                      className="button-view"
                      style={{ height: 47, width: 110, marginLeft: 5 }}
                      onClick={() => handleOpenModalOrigemDestino()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  )}
                </div>


              </div>
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeAirRoute && airRoute && airRoute.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Registro da viagem'}
                        </th>
                        <th className="head-tr-item" style={{ width: 360 }}>
                          {'Partida'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'O aeroporto de partida é válido?'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Chegada'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'O aeroporto de chegada é válido?'}
                        </th>
                        <th className="head-tr-item" style={{ width: 250 }}>
                          {'Distância do trecho (km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 400 }}>
                          {'Carga transportada (toneladas)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Distância x massa (t.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CO₂ (kg CO₂ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de CH₄ (kg CH₄ / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Fator de emissão de N₂o (kg N₂o / p.km)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th className="head-tr-item" style={{ width: 50 }} />
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {airRoute.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`airRoute.${index}.register`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `airRoute.${index}.register`,
                                      event.target.value
                                    )
                                    airRoute[index].register = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`airRoute.${index}.origin`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = value.toUpperCase()

                                    event.target.value = numberValue

                                    airRoute[index].origin = numberValue

                                    setValue(`airRoute.${index}.origin`, numberValue)

                                    handleChangeRoute(index)
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {airRoute[index].origin_valid_airport ? 'Sim' : 'Não'}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`airRoute.${index}.destiny`)}
                                  onChange={(event) => {
                                    const { value } = event.target
                                    const numberValue = value.toUpperCase()

                                    event.target.value = numberValue

                                    airRoute[index].destiny = numberValue

                                    setValue(`airRoute.${index}.destiny`, numberValue)

                                    handleChangeRoute(index)
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {airRoute[index].destiny_valid_airport ? 'Sim' : 'Não'}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].stretch_distance), false)}
                                </span>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 120, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`airRoute.${index}.transported_volume`, {
                                    required: true
                                  })}
                                  onChange={(event) => {

                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    airRoute[index].transported_volume = value

                                    setValue(`airRoute.${index}.transported_volume`, formatValue)

                                    handleChangeRoute(index, 'transported_volume')
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'center',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].distance_mass), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].factor_kg_co2_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].factor_kg_ch4_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 200,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].factor_kg_n2o_l), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].emission_co2), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].emission_ch4), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].emission_n2o), false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(Number(airRoute[index].emission_tco2e), false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }}>
                                  <div
                                    onClick={() => handleDeleteRoute(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={11}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2AirRoute, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4AirRoute, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oAirRoute, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eAirRoute, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {airRoute && airRoute.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum registro cadastrado`
                        : `Nenhum registro foi informado, clique em 'novo' para incluir seu registro`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.airRoute && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {ignoreFill === false && (
            <div style={{display: 'flex',flexDirection: 'column',width: '98%',marginTop: 10,marginLeft: 10}}>
              <div style={{display: 'flex',flexDirection: 'row',marginTop: 0,justifyContent: 'space-between',marginBottom: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 30 }}>
                  <span style={{textAlign: 'start',fontSize: 14,marginTop: 25,fontWeight: 'bold'}}>
                    Opção 2 - Cálculo de emissões de gases de efeito estufa por transporte aéreo de carga a partir do consumo de combustível no ano inventariado
                  </span>
                  <span style={{ textAlign: 'start', fontSize: 12, marginTop: 5 }} >
                    Selecione, o tipo de combustível ao lado, para adicionar nova linha
                  </span>
                </div>
              {fuels && !isCommonUser && (
                <div style={{marginTop: 15,marginRight: 10,display: 'flex',flexDirection: 'row',justifyContent: 'center'}}>
                  <Controller
                    control={control}
                    name="fuel_private_vehicle"
                    render={({ field }) => (
                      <Select placeholder="[Selecione]"
                      {...field}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          width: '350px',
                          height: 40,
                          backgroundColor: 'rgb(246, 246, 246)'
                        }),
                          option: (styles) => {
                            return { ...styles, color: '#31363F' }
                          }
                        }}
                        defaultValue={getValues('fuel_private_vehicle')}
                        options={fuels}
                        onChange={handleChangeCarFleetFuel}
                        />
                        )}
                        />
                  <button
                    className="button-view"
                    style={{ height: 40, width: 110, marginLeft: 5 }}
                    onClick={() => handleOpenModalConsumoCombustivel()}
                    type="button"
                    >
                    Importar Dados
                  </button>
                </div>
              )}
              </div>
              <div
                style={{
                  overflow: 'auto',
                  position: 'relative',
                  marginTop: 0,
                  width: 'calc(100vw - 320px)',
                  maxWidth: 'calc(100vw - 320px)'
                }}
              >
                {scopeAirFuel && airFuel && airFuel.length > 0 && (
                  <table
                    style={{
                      maxWidth: '100%',
                      width: '4000px',
                      borderCollapse: 'collapse'
                    }}
                    className="table-scope"
                  >
                    <thead>
                      <tr>
                        <th className="head-tr-item" style={{ width: 360 }} rowSpan={2}>
                          {'Registro da frota'}
                        </th>
                        <th className="head-tr-item" style={{ width: 360 }} rowSpan={2}>
                          {'Descrição da fonte'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Tipo de combustível'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Consumo anual'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Unidades'}
                        </th>
                        <th className="head-tr-item" style={{ width: 250 }} colSpan={2}>
                          {'O combustível utilizado é formado por'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} colSpan={3}>
                          {'Fatores de Emissão do combustível fóssil'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CO₂ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de CH₄ (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões de N₂O (t)'}
                        </th>
                        <th className="head-tr-item" style={{ width: 300 }} rowSpan={2}>
                          {'Emissões totais em CO₂e (t)'}
                        </th>
                        {!isCommonUser && (
                          <th
                            className="head-tr-item"
                            style={{ width: 50 }}
                            rowSpan={2}
                          />
                        )}
                      </tr>
                      <tr>
                        <th className="head-tr-item-bottom" style={{ width: 120 }}>
                          {'Combustível fóssil'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 240 }}>
                          {'Biocombustível'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CO₂ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg CH₄ / litro'}
                        </th>
                        <th className="head-tr-item-bottom" style={{ width: 200 }}>
                          {'kg N₂O / litro'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {airFuel.map((fuel: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`airFuel.${index}.source`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(`airFuel.${index}.source`, event.target.value)
                                    airFuel[index].source = event.target.value
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 200 }}
                                  className="input-item"
                                  {...register(`airFuel.${index}.description`, {
                                    required: true
                                  })}
                                  onChange={(event) => {
                                    setValue(
                                      `airFuel.${index}.description`,
                                      event.target.value
                                    )
                                    airFuel[index].description = event.target.value
                                  }}
                                />
                              </td>
                              <td style={{ width: 200 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                  >
                                    <span
                                      style={{
                                        marginLeft: 5,
                                        width: 200,
                                        fontSize: 12,
                                        fontWeight: 'bold',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {airFuel[index].vehicle_fuel_name}
                                    </span>
                                  </div>

                                  {airFuel[index].vehicle_fuel_description && (
                                    <div className="tooltip">
                                      <img src={InfoButtonMin} alt="information"/>
                                      <span className="tooltiptext">
                                        {airFuel[index].vehicle_fuel_description}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: 'center' }}
                                  className="input-item"
                                  {...register(`airFuel.${index}.consumption_yearly`)}
                                  onChange={(event) => {
                                    const formatValue = currency(event)
                                    const valueStr = formatValue.replace('.', '')
                                    const value = valueStr
                                      ? parseFloat(valueStr.replace(',', '.'))
                                      : 0

                                    airFuel[index].consumption_yearly = value

                                    setValue(
                                      `airFuel.${index}.consumption_yearly`,
                                      formatValue
                                    )

                                    handleChangeFuel(index, 'consumption_yearly')
                                  }}
                                />
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {'litros'}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {airFuel[index].fuel_name}
                                </span>
                              </td>
                              <td>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {airFuel[index].bio_fuel_name}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].factor_co2_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].factor_ch4_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].factor_n2o_kg, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].emission_co2, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }} >
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].emission_ch4, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].emission_n2o, false)}
                                </span>
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 120,
                                    fontSize: 12,
                                    textAlign: 'right',
                                    display: 'inline-block'
                                  }}
                                >
                                  {formatPrice3(airFuel[index].emission_tco2e, false)}
                                </span>
                              </td>
                              {!isCommonUser && (
                                <td style={{ textAlign: 'center' }} >
                                  <div
                                    onClick={() => handleDeleteFuel(index)}
                                    style={{
                                      cursor: 'pointer',
                                      textAlign: 'center',
                                      width: 70
                                    }}
                                  >
                                    <img src={TrashIcon} alt="lixeirinha"/>
                                  </div>
                                </td>
                              )}
                            </tr>
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                    <tfoot>
                      <tr style={{ fontWeight: 'bold' }}>
                        <td
                          colSpan={10}
                          className="head-tr-item"
                          style={{ paddingRight: 5, textAlign: 'right' }}
                        >
                          {'Total'}
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCo2AirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionCh4AirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionN2oAirFuel, false)}
                          </span>
                        </td>
                        <td className="head-tr-item">
                          <span
                            style={{
                              marginRight: 5,
                              width: 120,
                              fontSize: 12,
                              textAlign: 'right',
                              display: 'inline-block'
                            }}
                          >
                            {formatPrice3(emissionTco2eAirFuel, false)}
                          </span>
                        </td>
                        {!isCommonUser && <td className="head-tr-item" />}
                      </tr>
                    </tfoot>
                  </table>
                )}

                {airFuel && airFuel.length === 0 && (
                  <Uninformed
                    text={
                      isCommonUser
                        ? `Nenhum tipo de combustível cadastrado`
                        : `Nenhum tipo de combustível foi selecionado, favor selecionar para dar
                      prosseguimento`
                    }
                  />
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10
                  }}
                >
                  {errors && errors.airFuel && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        margin: 10
                      }}
                    >
                      <span
                        className="requiredLabel"
                        style={{ fontSize: 14, marginTop: 10 }}
                      >
                        * Todos os campos são obrigatórios
                      </span>
                    </div>
                  )}
                </div>
              </div>

              {scopeAirFuel && scopeAirRoute && (
                <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                              width: "100%", borderColor: "#9E9E9E", height: "40px", 
                              display: "flex", flexDirection: "row", marginLeft: 0}} >
                  <span style={{ margin: 10, width: "70%" }}>
                    Emissões totais em CO₂ equivalente (toneladas métricas):
                  </span>
                  <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                    {formatPrice3(emissionTco2eAirFuel + emissionTco2eAirRoute, false)}
                  </span>
                </div>
              )}
            </div>
          )}

          {ignoreFill === false && (
            <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
              <div
                style={{ width: '100%', borderTop: '0.6px solid rgba(49, 54, 63, 0.35)' }}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 5,
                  marginRight: 30
                }}
              >
                <span
                  style={{
                    textAlign: 'start',
                    fontSize: 16,
                    marginTop: 5,
                    marginLeft: 10,
                    fontWeight: 'bold'
                  }}
                >
                  Evidências do escopo informado
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}>
                  Observações sobre o escopo informado.
                </label>

                <textarea
                  readOnly={isCommonUser}
                  style={{ width: '100%', height: 100 }}
                  {...register('evidence')}
                />

                {errors.evidence && (
                  <span className="requiredLabel">Esse campo é obrigatório</span>
                )}

                <ScopeEvidenceAttachments
                  files={files}
                  onDeleteFile={handleDeleteFile}
                  onDownloadFile={downloadFile}
                  onChangeAttachment={attachmentChangeHandler}
                />
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && (
            <span style={{ fontSize: 12, textAlign: 'justify' }}>
              {Parser(guidelines)}
            </span>
          )}
        </div>
      </Modal>
      {openModalAeroKm && (
        <ModalImportData
          entityType={1}
          isOpen={openModalAeroKm}
          handleModalVisible={handleOpenModalConsumoCombustivel}
          entityName="Transporte e Distribuição Upstream em aeronaves de acordo com consumo de combustível"
          templateFileName="template_tdupstream_Air2.xlsx"
          handleImportData={handleImportConsuption}
        />
      )}

      {openModalAeroporto && (
        <ModalImportData
          entityType={2}
          isOpen={openModalAeroporto}
          handleModalVisible={handleOpenModalOrigemDestino}
          entityName="Transporte e Distribuição Upstream em aeronaves de acordo aeroportos de origem e destino e carga fracionada"
          templateFileName="template_tdupstream_Air1.xlsx"
          handleImportData={handleImportRoutes}
        />
      )}
    
      {openModalError && importErrors && (
        <Modal isOpen={openModalError} isPayOrTypeClient={true} handleModalVisible={handleOpenModalError} >
          <div style={{ margin: 5, textAlign: "left"  }}>
            <TitleText level={3} title="Falha na importação de dados" color="red" />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }} >
                Ocorreram falhas na importação dos dados previstos. Abaixo maiores detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }} >
            <table style={{ width: '100%',  borderCollapse: 'collapse' }} className="table-scope" >
              <thead>
                <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                  <th style={{ backgroundColor: "#D8D7D7", fontSize: 12, height: 25 }} colSpan={3}>
                    {'Falhas de Importação'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr style={{ borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {'Linha ' + item.id}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "20%", fontSize: 12, textAlign: "left" }} >
                            {item.type === 1 ? 'Origem' : item.type === 2 ? 'Destino' : 'Geral'}
                          </span>
                        </td>
                        <td style={{ background: "#F6F6F6", borderColor: "white", borderWidth: 1, borderStyle: "solid" }}>
                          <span style={{ marginLeft: 5, width: "80%", fontSize: 12, textAlign: "left" }} >
                            {'Item não identificado: ' + item.label}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Modal >
      )}

      {loadingModal && (
        <Loading isOpen={loadingModal}
        />
      )}
    </Container>
  )
}
