import { MaccProject, MaccResult } from "../pages/MaccCurve/interfaces/MaccProjetcs";

export function formatInputNumber(event: React.FormEvent<HTMLInputElement>) {
    const rawValue = event.currentTarget.value;
    const formatValue = currency(rawValue);
    const valueStr = formatValue.replace('.', '');
    const value = valueStr ? parseFloat(valueStr.replace(',', '.')) : 0;

    return { value, formatValue };
}

export function currency(value: string) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{3})$/, '$1,$2');
    return value;
}

export function currencyMonetary(value: string, currencySymbol?: string) {
    value = value.replace(/,/g, '.');
    value = value.replace(/[^\d.]/g, '');
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
        return '';
    }
    value = numberValue.toFixed(2);
    value = value.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (currencySymbol) {
        value = `${currencySymbol} ${value}`;
    }

    return value;
}


export function formatSufix(value: string, currencySymbol: string) {
    const formattedValue = currencyMonetary(value);
    return `${currencySymbol} ${formattedValue}`;
}
export function formatSufixWithoutFormat(value: string, currencySymbol: string) {
    return `${currencySymbol} ${value}`;
}
export function formatPrefix(value: string, currencySymbol: string) {
    const formattedValue = currencyMonetary(value);
    return `${formattedValue} ${currencySymbol} `;
}
export function formatPrefixWithoutFormat(value: string, currencySymbol: string) {
    return `${value} ${currencySymbol} `;
}

export function svgToPngBase64(svgElement: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();

        img.onload = function() {
            canvas.width = img.width ;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            const pngData = canvas.toDataURL("image/png");
            resolve(pngData);
        };

        img.onerror = error => {
            reject(new Error("Error loading SVG for conversion to PNG: " + error));
        };

        img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgData);
    });
}

export function calculateCMAandEmissions(projects: MaccProject[]) {
    const calculatedCMA: MaccResult[] = [];
    const cumulativeCosts: any[] = [];
    const cumulativeCOAvoided: any[] = [];
    let cumulative = 0;
    let cumulativeAvoided = 0;

    for (const project of projects) {
        const net_annual_cashflow = project.financial_beneficiary - project.operational_cost;
        const NPV =Array.from({ length: project.lifetime }, (_, i) => i).reduce((acc, i) => {
            return acc + net_annual_cashflow / Math.pow(1 + project.tax_fees, i + 1);
        }, 0);
        const total_emission_reduction = project.annual_emission * project.lifetime;

        const CMA = (project.investment - NPV) / total_emission_reduction;

        cumulative += CMA;
        cumulativeCosts.push(cumulative);
        cumulativeAvoided += total_emission_reduction;
        cumulativeCOAvoided.push(cumulativeAvoided);
        
        calculatedCMA.push({
            id: project.company_id,
            name: project.project,
            tCO2: total_emission_reduction,
            cost: CMA,
            projectObj: project
        });
        
    }

    return {calculatedCMA, cumulativeCosts, cumulativeCOAvoided};
}

