import { api } from "./api";

async function getById(car_fleetId: number) {
  try {
    const { data } = await api.get(`/car_fleet/${car_fleetId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/car_fleet", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(car_fleet: any) {
  try {
    const { data } = await api.post(`/car_fleet`, car_fleet);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(car_fleetId: number, car_fleet: any) {
  try {
    const { data } = await api.put(`/car_fleet/${car_fleetId}`, car_fleet);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(car_fleetId: number) {
  try {
    const { data } = await api.delete(`/car_fleet/${car_fleetId}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function getItensByCarFleet(car_fleetId: number) {
  try {
    const { data } = await api.get(`/car_fleet/years/${car_fleetId}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function updateItensByCarFleet(car_fleetId: number, itens: any) {
  try {
    const { data } = await api.put(`/car_fleet/years/${car_fleetId}`, itens);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

const carFleetService = {
  getById,
  getByParams,
  getItensByCarFleet,
  updateItensByCarFleet,
  create,
  updateById,
  deleteById
};

export default carFleetService;
