import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import { Container } from "./styles";
import { formatPrice14, formatPrice17 } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import carFleetService from "../../../services/carFleetService";
import { toast } from "react-hot-toast";

export const CarFleetYear = () => {
  const history = useHistory();

  const [carFleetSelected, setCarFleetSelected] = useState<any>({});
  const location = useLocation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [years, setYears] = useState<any>([]);

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.fuel_id){
      getFuelsById(item.fuel_id)
    }else{
      history.push('/tipo-frota')
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (formData) {
      const itens:any[] = years.map((year: any, index: number) => {
        const factor_ch4_g_km = formData.year[index].factor_ch4_g_km.replace(".", "");
        const factor_n2o_g_km = formData.year[index].factor_n2o_g_km.replace(".", "");
        const average_consumption = formData.year[index].average_consumption.replace(".", "");

        return {
          year: year.year,
          factor_ch4_g_km: factor_ch4_g_km ? parseFloat(factor_ch4_g_km.replace(",", ".")) : 0,
          factor_n2o_g_km: factor_n2o_g_km ? parseFloat(factor_n2o_g_km.replace(",", ".")) : 0,
          average_consumption: average_consumption ? parseFloat(average_consumption.replace(",", ".")) : 0,
        };
      })

      const request = {
        itens: itens,
      };
      const result = await carFleetService.updateItensByCarFleet(
        carFleetSelected.id,
        request
      );
      if (result && !result.error) {
        await getFuelsById(carFleetSelected.id);
       // history.goBack()

       // reset();
       // setErrorApi(undefined);
          
        toast.success("Operação realizada com sucesso!")
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }
  };

  async function getFuelsById(id: number) {
    const carFleet = await carFleetService.getById(id);
  
    const itens = await carFleetService.getItensByCarFleet(id);
  
    const years = [];
  
    const yearLength = new Date().getFullYear() - 2000 + 1;
  
    for (let i = 0; i < yearLength; i++) {
      let year = i === 0 ? null : 2000 + i;
      const yearLabel = i === 0 ? 'até 2000' : 2000 + i;
  
      // If year is null, set it to 2000
      year = year === null ? 2000 : year;
  
      const item = getItem(year, itens.itens);
  
      const factor_ch4_g_km = item && item.factor_ch4_g_km ? item.factor_ch4_g_km : 0;
      const factor_n2o_g_km = item && item.factor_n2o_g_km ? item.factor_n2o_g_km : 0;
      const average_consumption = item && item.average_consumption ? item.average_consumption : 0;
  
      setValue(`year.${i}.factor_ch4_g_km`, formatPrice17(Number(factor_ch4_g_km), false));
      setValue(`year.${i}.factor_n2o_g_km`, formatPrice17(Number(factor_n2o_g_km), false));
      setValue(`year.${i}.average_consumption`, formatPrice14(Number(average_consumption), false));
  
      years.push({
        year: year as number, // Assegurando que year seja number
        year_label: yearLabel,
        factor_ch4_g_km: factor_ch4_g_km,
        factor_n2o_g_km: factor_n2o_g_km,
        average_consumption: average_consumption,
      });
    }
  
    setYears(years);
    setCarFleetSelected(carFleet);
  }
  
    
  function getItem(year: number | undefined, itens?: any) {
    if (itens) {
      for (const item of itens) {
        // Verifica se o ano é igual ao ano especificado ou se o item é indefinido e assume o ano 2000
        if ((year !== undefined && item.year === year) || (year === undefined && item.year === 2000)) {
          return item;
        }
      }
    }
  
    return undefined;
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
      let value = e.currentTarget.value;
      // value = value.replace(/\D/g, "");
      // value = value.replace(/(\d)(\d{4})$/, "$1,$2");
      // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value;
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TitleText level={1} title={carFleetSelected.name} />
          </div>
          
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >
          <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, justifyContent: "space-between" }}>
            <TitleText level={2} title="Fatores de Emissão - MMA (2014) p/ ano" />
          </div>

          <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 190px)", 
            maxHeight: "calc(100vh - 190px)"}} >
            {years && years.length > 0 && (
              <table style={{ width: "90%", borderCollapse: "collapse", marginTop: 5}}>
                <thead>
                  <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                    <th style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"Ano"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"CH₄g/km)"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"N₂Og/km)"}
                    </th>
                    <th style={{ textAlign: "center", padding: "5px", color: "#7A7D83", fontSize: 12 }}>
                      {"consumo médio"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {years && years.length > 0 && years.map((year: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td style={{ padding: "10px", width: "19%" }} >
                              <span className="list-text title-text-underline">
                                {year?.year_label}
                              </span>
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.factor_ch4_g_km`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.factor_ch4_g_km`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.factor_n2o_g_km`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.factor_n2o_g_km`, currency(event));
                              }}
                            />
                          </td>
                          <td style={{ padding: "10px", width: "27%" }} >
                            <input style={{ width: "90%", textAlign: "right" }}
                              {...register(`year.${index}.average_consumption`, { required: true })}
                              onChange={(event) => {
                                  setValue(`year.${index}.average_consumption`, currency(event));
                              }}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};
