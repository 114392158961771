import { Page, Text, Image as ImagePDF, View } from "@react-pdf/renderer";
import React from "react";
import sinergiaAzul from "../../../../../../../assets/images/logo-azul.png";
import { styles } from "../../styles";
import { ResumeData } from "../../../../../../Inventory/components/Reports/interfaces/ResumeData";
import { InventoryData } from "../../../../../../Inventory/components/Reports/interfaces/InventoryData";

interface Page1Props {
  year?: number;
  cover?: string;
  companyName?: string;
  logo?: string;
}

export interface IGEEProps {
  data?: InventoryData;
  resume?: ResumeData;
}

export const Capa: React.FC<Page1Props & IGEEProps> = (props) => {
  const { logo, year, cover, companyName } = props;

  const coverImage = cover === undefined ? null : cover;

  return (
    <Page size={[640, 480]}>
      <View style={{ height: "100%", backgroundColor: "#ecf3f4", position: 'relative' }}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 20 }}>
          <ImagePDF 
            src={logo}
            style={{
              width: 60,
              height: 40,
              marginRight: '12px',
              marginTop: -8
            }}
          />
          <ImagePDF
            src={sinergiaAzul}
            style={{
              width: 65,
              height: 25
            }}
          />
        </View>
        <View 
          style={{
            backgroundColor: 'transparent', 
            width: 85, 
            height: 115, 
            borderRadius: 50, 
            marginLeft: -30,
            justifyContent: 'center', 
            alignItems: 'center', 
            borderWidth: 1.5, 
            borderColor: '#80b780', 
            top: -25
          }}
        />
        <View style={styles.upTitleView}>
          <Text style={styles.homeTitle}>
            Inventário de Emissões de Gases de Efeito Estufa{" "}
            <Text style={{ ...styles.homeTitle, ...styles.extraBold, ...styles.boldItalic }}>
              {year}
            </Text>
          </Text>
          <Text style={{...styles.homeCompany, ...styles.boldItalic, flexWrap: 'wrap' }}>{companyName}</Text>
        </View>
        <View style={{ height: "70%", width: "100%", position: 'relative' }}>
          {coverImage && (
            <ImagePDF
              src={coverImage}
              style={{ objectFit: "cover"}}
            />
          )}
        </View>
      </View>
    </Page>
  );
};
