import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { ModalDelete } from "../../components/ModalDelete";
import { Pagination } from "../../components/Pagination";
import { TitleText } from "../../components/TitleText";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import vehicleFuelService from "../../services/vehicleFuelService";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

export const VehicleFuel = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [vehicleFuels, setVehicleFuels] = useState<any>([]);
  const [vehicleFuelSelected, setVehicleFuelSelected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getVehicleFuels(page: number, filter: string) {
    const result = await vehicleFuelService.getByParams({ limit: 55, page, filter: filter });
  
    const uniqueFuels = Array.from(new Map(result.itens.map((fuel: any) => [fuel.name, fuel])).values());
    setVehicleFuels({ ...result, itens: uniqueFuels });
  
    setCurrentPage(page);
  }
  
  useEffect(() => {
    getVehicleFuels(1, searchDebounced);
  },  // eslint-disable-next-line react-hooks/exhaustive-deps
   []);

  useEffect(() => {
    getVehicleFuels(1, searchDebounced)
  },  [searchDebounced]);

  async function handleDeleteVehicleFuel() {
    if (vehicleFuelSelected) {
      const result = await vehicleFuelService.deleteById(
        vehicleFuelSelected?.id
      );

      if (result) {
        setOpenModalDelete(false);
        getVehicleFuels(1, searchDebounced);

        toast.success("Operação realizada com sucesso!")
      }
    }
  }

  async function getVehicleFuelsById(id: number) {
    const vehicleFuel = await vehicleFuelService.getById(id);

    setVehicleFuelSelected(vehicleFuel)
  }

  function redirectToEdit(vehicleFuel?: any) {
    if (vehicleFuel){
      history.push({
          pathname: '/combustivel-particular/edicao',
          state: { vehicleFuel_id: vehicleFuel.id }
      });
    }else{
      history.push('/combustivel-particular/edicao')
    }
  }

  function handleOpenModalDelete(vehicleFuel?: any) {
    setOpenModalDelete(!openModalDelete);

    if (vehicleFuel) {
      getVehicleFuelsById(vehicleFuel.id);
    } else {
      setVehicleFuelSelected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getVehicleFuels(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getVehicleFuels(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="Composições de combustível" />

        <input
          className="input-search"
          placeholder="Pesquisar composições de combustívels"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        
        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo combustível
        </Button>
      </div>

      {vehicleFuels?.itens &&
        (
          <div style={{ overflow: "hidden auto", position: "relative", marginTop: 10,  height: "calc(100vh - 130px)", 
              maxHeight: "calc(100vh - 130px)"}} >
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Nome"}
                  </th>
                  <th
                    style={{ textAlign: "start", padding: "5px", color: "#7A7D83", fontWeight: "700" }}
                  >
                    {"Descrição"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicleFuels.itens.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span className="title-text-underline">
                            {item.name}
                          </span>
                        </td>
                        <td style={{ padding: "10px", width: "45%" }} >
                          <span>
                            {item.description}
                          </span>
                        </td>
                        <td>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <button
                              className="button-delete"
                              style={{ marginRight: 5 }}
                              onClick={() => handleOpenModalDelete(item)}
                            >
                              Excluir
                            </button>
                            <button
                              className="button-edit"
                              onClick={() => redirectToEdit(item)}
                            >
                              Editar
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
              {vehicleFuels?.itens?.length > 0 && (
                <Pagination
                  total={vehicleFuels.total}
                  limit={55}
                  goNext={goNext}
                  goPrev={goPrev}
                  currentPage={currentPage}
                  length={vehicleFuels.itens.length}
                />
              )}

          </div>
        )
      }

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={vehicleFuelSelected.name}
          handleOk={handleDeleteVehicleFuel}
        />
      )}
    </Container>
  );
};
