import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../../components/Button/styles";
import { GoBack } from "../../../../components/GoBack/index";
import { TitleText } from "../../../../components/TitleText/index";
import useAuth from "../../../../hooks/useAuth";
import fuelService from "../../../../services/fuelService";
import { Container } from "../../../Fuel/styles";
import { formatPrice14 } from "../../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  name: string;
  pci: any;
  density: any;
  emission_kgtj: any;
  name_ipcc: string;
  unity: string;
  description: string;
  fuel_type: string;
  reference: string;
  factor_co2: any;
  factor_ch4: any;
  factor_n2o: any;
  factor_kg_gee_co2: any;
  factor_kg_gee_ch4: any;
  factor_kg_gee_n2o: any;
  factor_thermal_energy_co2: any;
  factor_thermal_energy_ch4: any;
  factor_thermal_energy_n2o: any;
  composition: string;
  emission_source: string;
  definition: string;
  reference_definition: string;
  year: any;
}

export const FuelAdd = () => {
  const history = useHistory();

  const [fuelSelected, setFuelSelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [fuelType, setFuelType] = useState<string | undefined>();
  const availableYears = [2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]; 

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.fuel_id){
      getFuelsById(item.fuel_id)
    }else{
      setValue("fuel_type", "1")
      setFuelType("1")
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [location]);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setValue("year", currentYear); 
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    console.log(errors)
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
   [errors]);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const pci = formData.pci.replace(".", "");
      const density = formData.density.replace(".", "");
      const emission_kgtj = formData.emission_kgtj.replace(".", "");
      const factor_co2 = formData.factor_co2.replace(".", "");
      // const factor_ch4 = formData.factor_ch4.replace(".", "");
      // const factor_n2o = formData.factor_n2o.replace(".", "");
      const factor_kg_gee_co2 = formData.factor_kg_gee_co2.replace(".", "");
      const factor_kg_gee_ch4 = formData.factor_kg_gee_ch4.replace(".", "");
      const factor_kg_gee_n2o = formData.factor_kg_gee_n2o.replace(".", "");
      const factor_thermal_energy_co2 = formData.factor_thermal_energy_co2.replace(".", "");
      const factor_thermal_energy_ch4 = formData.factor_thermal_energy_ch4.replace(".", "");
      const factor_thermal_energy_n2o = formData.factor_thermal_energy_n2o.replace(".", "");

      const FuelAdd = {
        name: formData.name,
        unity: formData.unity,
        name_ipcc: formData.name_ipcc,
        reference: formData.reference,
        definition: formData.definition,
        composition: formData.composition,
        fuel_type: parseInt(formData.fuel_type),
        emission_source: formData.emission_source, 
        pci: pci ? parseFloat(pci.replace(",", ".")) : 0,
        reference_definition: formData.reference_definition,
        density: density ? parseFloat(density.replace(",", ".")) : 0,
        factor_co2: factor_co2 ? parseFloat(factor_co2.replace(",", ".")) : 0,
        // factor_ch4: factor_ch4 ? parseFloat(factor_ch4.replace(",", ".")) : 0,
        // factor_n2o: factor_n2o ? parseFloat(factor_n2o.replace(",", ".")) : 0,
        emission_kgtj: emission_kgtj ? parseFloat(emission_kgtj.replace(",", ".")) : 0,
        factor_kg_gee_co2: factor_kg_gee_co2 ? parseFloat(factor_kg_gee_co2.replace(",", ".")) : 0,
        factor_kg_gee_ch4: factor_kg_gee_ch4 ? parseFloat(factor_kg_gee_ch4.replace(",", ".")) : 0,
        factor_kg_gee_n2o: factor_kg_gee_n2o ? parseFloat(factor_kg_gee_n2o.replace(",", ".")) : 0,
        factor_thermal_energy_co2: factor_thermal_energy_co2 ? parseFloat(factor_thermal_energy_co2.replace(",", ".")) : 0,
        factor_thermal_energy_ch4: factor_thermal_energy_ch4 ? parseFloat(factor_thermal_energy_ch4.replace(",", ".")) : 0,
        factor_thermal_energy_n2o: factor_thermal_energy_n2o ? parseFloat(factor_thermal_energy_n2o.replace(",", ".")) : 0,
        year: parseInt(formData.year, 10),
        description: formData.description
      };

      if (fuelSelected?.id) {
        const result = await fuelService.updateById(
          fuelSelected.id,
          FuelAdd
        );

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await fuelService.create(FuelAdd);

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getFuelsById(id: number) {
    const fuel = await fuelService.getById(id);

    setValue("name", fuel?.name);
    setValue("unity", fuel?.unity);
    setValue("name_ipcc", fuel?.name_ipcc);
    setValue("reference", fuel?.reference);
    setValue("emission_source", fuel?.emission_source);
    setValue("definition", fuel?.definition);
    setValue("description", fuel?.description);
    setValue("composition", fuel?.composition);
    setValue("fuel_type", fuel?.fuel_type.toString());
    setValue("pci", formatPrice12(Number(fuel.pci)));
    setValue("reference_definition", fuel?.reference_definition);
    setValue("density", formatPrice12(Number(fuel.density)));
    setValue("factor_co2", formatPrice12(Number(fuel.factor_co2)));
    setValue("factor_ch4", formatPrice12(Number(fuel.factor_ch4)));
    setValue("factor_n2o", formatPrice12(Number(fuel.factor_n2o)));
    setValue("emission_kgtj", formatPrice12(Number(fuel.emission_kgtj)));
    setValue("factor_kg_gee_co2", formatPrice12(Number(fuel.factor_kg_gee_co2)));
    setValue("factor_kg_gee_ch4", formatPrice12(Number(fuel.factor_kg_gee_ch4)));
    setValue("factor_kg_gee_n2o", formatPrice12(Number(fuel.factor_kg_gee_n2o)));
    setValue("factor_thermal_energy_co2", formatPrice12(Number(fuel.factor_thermal_energy_co2)));
    setValue("factor_thermal_energy_ch4", formatPrice12(Number(fuel.factor_thermal_energy_ch4)));
    setValue("factor_thermal_energy_n2o", formatPrice12(Number(fuel.factor_thermal_energy_n2o)));
    setValue("year", fuel?.year);

    setFuelType(fuel?.fuel_type.toString())

    setFuelSelected(fuel)
  }
    
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{12})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
}

function formatPrice12(value: number): string {
  let formattedValue = value.toFixed(12);

  formattedValue = formattedValue.replace('.', ',');
  const parts = formattedValue.split(',');
  const integerPart = parts[0];
  const decimalPart = parts[1] || '';
  const integerPartWithThousandsSeparator = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${integerPartWithThousandsSeparator},${decimalPart}`;
}

async function handleYearChange(event: React.ChangeEvent<HTMLSelectElement>) {
  const selectedYear = parseInt(event.target.value, 10);
  const name = fuelSelected?.name || '';
  try {
    const fuel = await fuelService.getByNameAndYear(name, selectedYear);
    if (fuel) {
      setValue("name", fuel.name);
      setValue("unity", fuel.unity);
      setValue("name_ipcc", fuel.name_ipcc);
      setValue("reference", fuel.reference);
      setValue("emission_source", fuel.emission_source);
      setValue("definition", fuel.definition);
      setValue("description", fuel.description);
      setValue("composition", fuel.composition);
      setValue("fuel_type", fuel.fuel_type.toString());
      setValue("pci", formatPrice12(Number(fuel.pci)));
      setValue("reference_definition", fuel.reference_definition);
      setValue("density", formatPrice12(Number(fuel.density)));
      setValue("factor_co2", formatPrice12(Number(fuel.factor_co2)));
      setValue("factor_ch4", formatPrice12(Number(fuel.factor_ch4)));
      setValue("factor_n2o", formatPrice12(Number(fuel.factor_n2o)));
      setValue("emission_kgtj", formatPrice12(Number(fuel.emission_kgtj)));
      setValue("factor_kg_gee_co2", formatPrice12(Number(fuel.factor_kg_gee_co2)));
      setValue("factor_kg_gee_ch4", formatPrice12(Number(fuel.factor_kg_gee_ch4)));
      setValue("factor_kg_gee_n2o", formatPrice12(Number(fuel.factor_kg_gee_n2o)));
      setValue("factor_thermal_energy_co2", formatPrice12(Number(fuel.factor_thermal_energy_co2)));
      setValue("factor_thermal_energy_ch4", formatPrice12(Number(fuel.factor_thermal_energy_ch4)));
      setValue("factor_thermal_energy_n2o", formatPrice12(Number(fuel.factor_thermal_energy_n2o)));
      setValue("year", fuel.year);
      setFuelType(fuel.fuel_type.toString());
      setFuelSelected(fuel);
    } else {
      reset();
    }
  } catch (error) {
    console.error("Erro ao buscar combustível:", error);
    setErrorApi("Erro ao buscar combustível.");
  }
}

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 20 }} >
          <TitleText level={1} title="Combustível" />
          <div style={{ display: "flex", alignItems: "right", marginLeft: 50, marginBottom: -10 }}>
              <TitleText level={1} title="Ano: "/>
                <select
                  style={{ 
                    width: '150%',
                    height: "75%", 
                    marginLeft: 10, 
                    padding: '10px', 
                    borderRadius: '4px', 
                    border: '1px solid #ccc', 
                    backgroundColor: '#f9f9f9',
                    marginTop: 5 
                  }}
                    {...register("year", { required: true })}
                    onChange={handleYearChange}
                >
                  {availableYears.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                    ))}
                </select>
            </div> 
  
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ overflow: "hidden auto", position: "relative", height: "calc(100vh - 150px)", 
            maxHeight: "calc(100vh - 150px)"}} >
          <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 20, marginLeft: 50, marginRight: 50}} >
            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Nome
                </label>

                <input style={{width: '100%'}}
                      {...register("name", { required: true })}/>
                            
                {errors.name && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Unidade
                </label>

                <input style={{width: '100%', height: 35}}
                      {...register("unity", { required: true })}/>
                            
                {errors.unity && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginBottom: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                  <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                    lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                      Tipo de combustível
                  </label>
                  <div style={{ display: 'flex', justifyContent: 'start',  alignItems: 'center', marginTop: 5}}>
                      <input type="radio" value="1" style={{width: 20, marginLeft: 0,  marginRight: 10}}
                              {...register("fuel_type", { required: true })}
                              onChange={(event) => {
                                  setFuelType(event.target.value)
                                  setValue("fuel_type", event.target.value);
                              }} /> 
                      <span>Combustível fóssil</span> 

                      <input type="radio" value="2" style={{width: 20, marginRight: 10}}
                              {...register("fuel_type", { required: true })}
                              onChange={(event) => {
                                  setFuelType(event.target.value)
                                  setValue("fuel_type", event.target.value);
                              }} /> 
                      <span>Biocombustível</span> 
                  </div>
                  {errors.fuel_type && (
                      <span style={{ color: "#E33F3F", marginTop: 10 }}>
                          Esse campo é obrigatório
                      </span>
                  )}
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    {fuelType === "1" && (
                      "Combustível fóssil"
                    )}
                    {fuelType === "2" && (
                      "Biocombustível"
                    )}
                </label>

                <input style={{width: '95%'}}
                      {...register("composition")}/>
                            
                {errors.composition && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
                </div>
            </div>

            <TitleText level={3} title="Fatores de emissão para combustão estacionária" />

            <div style={{ display: "flex", flexDirection: "row", width: "95%", marginTop: 10 }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Combustível correspondente adotado pelo IPCC
                </label>

                <input style={{width: '95%'}}
                      {...register("name_ipcc")}/>
                            
                {errors.name_ipcc && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "50%" }}>
                <label style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Referência
                </label>

                <input style={{width: '95%'}}
                      {...register("reference")}/>
                            
                {errors.reference && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Poder Calorífico Inferior (PCI) - (GJ/t)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("pci")}
                      onChange={(event) => {
                          setValue("pci", currency(event));
                      }}/>
                            
                {errors.pci && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Densidade (kg/unidade)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("density")}
                      onChange={(event) => {
                          setValue("density", currency(event));
                      }}/>
                            
                {errors.density && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>
            
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Definição
            </label>

            <textarea style={{width: '92%', height: 35}}
                  {...register("definition")}/>
                        
            {errors.definition && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                  Referencia (definição)
                </label>

                <input style={{width: '92%'}}
                      {...register("reference_definition")}/>
                            
                {errors.reference_definition && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Fatores de emissão - CO₂ (kg/un.)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_co2")}
                      onChange={(event) => {
                          setValue("factor_co2", currency(event));
                      }}/>
                            
                {errors.factor_co2 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              {/* <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Fatores de emissão - CH₄ (kg/un.)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_ch4")}
                      onChange={(event) => {
                          setValue("factor_ch4", currency(event));
                      }}/>
                            
                {errors.factor_ch4 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div> */}

              {/* <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Fatores de emissão - N₂O (kg/un.)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_n2o")}
                      onChange={(event) => {
                          setValue("factor_n2o", currency(event));
                      }}/>
                            
                {errors.factor_n2o && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div> */}
            </div>

            <TitleText level={3} title="Fatores de Emissão" />

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    CO₂ (kg/TJ)
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("emission_kgtj")}
                      onChange={(event) => {
                          setValue("emission_kgtj", currency(event));
                      }}/>
                            
                {errors.emission_kgtj && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "60%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    Fonte
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("emission_source")}/>
                            
                {errors.emission_source && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>

            <TitleText level={3} title="Fatores de emissão por utilização de combustíveis em fontes móveis" />

            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", textDecoration: "underline" }} >
                Fatores de Emissão (kg GEE/un.)
            </label>

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    CO₂
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_kg_gee_co2")}
                      onChange={(event) => {
                          setValue("factor_kg_gee_co2", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_co2 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    CH₄
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_kg_gee_ch4")}
                      onChange={(event) => {
                          setValue("factor_kg_gee_ch4", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_ch4 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    N₂O
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_kg_gee_n2o")}
                      onChange={(event) => {
                          setValue("factor_kg_gee_n2o", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_n2o && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>
            </div>

            <TitleText level={3} title="Fatores de emissão por utilização de combustíveis" />

            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold", textDecoration: "underline" }} >
                Fatores de Emissão (kg GEE/un.)
            </label>

            <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    CO₂
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_thermal_energy_co2")}
                      onChange={(event) => {
                          setValue("factor_thermal_energy_co2", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_co2 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    CH₄
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_thermal_energy_ch4")}
                      onChange={(event) => {
                          setValue("factor_thermal_energy_ch4", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_ch4 && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

              <div style={{ display: "flex", flexDirection: "column", marginRight: 10, width: "33%" }}>
                <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
                  lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                    N₂O
                </label>

                <input style={{width: '95%', height: 35}}
                      {...register("factor_thermal_energy_n2o")}
                      onChange={(event) => {
                          setValue("factor_thermal_energy_n2o", currency(event));
                      }}/>
                            
                {errors.factor_kg_gee_n2o && (
                    <span className='requiredLabel' >Esse campo é obrigatório</span>
                )}
              </div>

            </div>

            
            <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
              lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
                Descrição
            </label>

            <textarea style={{width: '92%', height: 100}}
                  {...register("description")}/>
                        
            {errors.description && (
                <span className='requiredLabel' >Esse campo é obrigatório</span>
            )}
          </div>
        </div>
      </form>
    </Container>
  );
};
