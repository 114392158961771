import Parser from "html-react-parser";
import Select from "react-select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { formatInputNumber } from "../../../../../utils/utils";
import useAuth from "../../../../../hooks/useAuth";
import { Container } from "./styles";
import { formatPrice3 } from "../../../../../utils/transform";
import inventoryService from "../../../../../services/inventoryService";
import scope3Service from "../../../../../services/scope3Service";
import scopeService from "../../../../../services/scopeService";
import TrashIcon from "../../../../../assets/icons/trash.svg";
import { Button } from "../../../../../components/Button/styles";
import { Modal } from "../../../../../components/Modal";
import { GoBack } from "../../../../../components/GoBack";
import { TitleText } from "../../../../../components/TitleText";
import ReportRecordsCheckbox from "../../../components/ReportRecordsCheckbox";
import ScopeEvidenceAttachments from "../../../components/ScopeEvidenceAttachments";
import { Uninformed } from "../../../../../components/Uninformed";
import { toast } from "react-hot-toast";
import homeOfficeService from "../../../../../services/homeOfficeService";
import { ModalImportData } from "../../../../../components/ModalImportData";
import utilsService from "../../../../../services/utilsService";
import { Loading } from "../../../../../components/Loading";
import { EletricityParams } from "../../../../../interfaces/ParamEmissonGeneric";

export const HomeWorkHomeOffice = () => {
  const history = useHistory();

  const [files, setFiles] = useState<any>([]);
  const [title, setTitle] = useState<string | undefined>();
  const [guidelines, setGuidelines] = useState<string | undefined>();

  const [importErrors, setImportErrors] = useState<any>([]);
  const [openModalError, setOpenModalError] = useState(false);
  const [openModalImport, setOpenModalImport] = useState(false);

  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [ignoreFill, setIgnoreFill] = useState(true);
  const [homeOffice, setHomeOffice] = useState<any>([]);
  const [homeEnergy, setHomeEnergy] = useState<any>([]);
  const [purchaseJan, setPurchaseJan] = useState<any>();
  const [purchaseFeb, setPurchaseFeb] = useState<any>();
  const [purchaseMar, setPurchaseMar] = useState<any>();
  const [purchaseApr, setPurchaseApr] = useState<any>();
  const [purchaseMay, setPurchaseMay] = useState<any>();
  const [purchaseJun, setPurchaseJun] = useState<any>();
  const [purchaseJul, setPurchaseJul] = useState<any>();
  const [purchaseAug, setPurchaseAug] = useState<any>();
  const [purchaseSep, setPurchaseSep] = useState<any>();
  const [purchaseOct, setPurchaseOct] = useState<any>();
  const [purchaseNov, setPurchaseNov] = useState<any>();
  const [purchaseDec, setPurchaseDec] = useState<any>();
  const [emissionCo2, setEmissionCo2] = useState<any>();
  const [emissionCh4, setEmissionCh4] = useState<any>();
  const [emissionN2o, setEmissionN2o] = useState<any>();
  const [loadingModal, setLoadingModal] = useState(false);
  const [purchaseTotal, setPurchaseTotal] = useState<any>();
  const [purchaseYearly, setPurchaseYearly] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scopeSelected, setScopeSelected] = useState<any>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  const [inventorySelected, setInventorySelected] = useState<any>({});
  const [changeHomeOffice, setChangeHomeOffice] = useState<string | undefined>();





  useEffect(() => {
    const item: any = location?.state;

    if (item && item.company_id && item.year) {
      getInventorysById(item.company_id, item.year, item.scope);
    } else {
      history.push({
        pathname: "/inventario/escopo-3",
      });
    }

    getGuidelines();
  },// eslint-disable-next-line react-hooks/exhaustive-deps
   [location]);

  async function getGuidelines() {
    const item = await scopeService.getBySubSlug(
      3,
      "deslocamento_casa_trabalho",
      "home_office"
    );

    setTitle(item.label);
    setGuidelines(item.guidelines);
  }

  function handleOpenInfo() {
    setOpenModal(!openModal);
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async (formData) => {
    setLoadingModal(true);

    if (formData) {
      const hoItems: any[] = homeOffice.map((scope: any, index: number) => {
        return {
          city: scope.city,
          state: scope.state,
          source: scope.source,
          description: scope.description,
          private_name: scope.private_name,
          company_name: scope.company_name,
          purchase_jan: scope.purchase_jan ? scope.purchase_jan : 0,
          purchase_feb: scope.purchase_feb ? scope.purchase_feb : 0,
          purchase_mar: scope.purchase_mar ? scope.purchase_mar : 0,
          purchase_apr: scope.purchase_apr ? scope.purchase_apr : 0,
          purchase_may: scope.purchase_may ? scope.purchase_may : 0,
          purchase_jun: scope.purchase_jun ? scope.purchase_jun : 0,
          purchase_jul: scope.purchase_jul ? scope.purchase_jul : 0,
          purchase_aug: scope.purchase_aug ? scope.purchase_aug : 0,
          purchase_sep: scope.purchase_sep ? scope.purchase_sep : 0,
          purchase_oct: scope.purchase_oct ? scope.purchase_oct : 0,
          purchase_nov: scope.purchase_nov ? scope.purchase_nov : 0,
          purchase_dec: scope.purchase_dec ? scope.purchase_dec : 0,
          emission_co2: scope.emission_co2 ? scope.emission_co2 : 0,
          emission_ch4: scope.emission_ch4 ? scope.emission_ch4 : 0,
          emission_n2o: scope.emission_n2o ? scope.emission_n2o : 0,
          purchase_total: scope.purchase_total ? scope.purchase_total : 0,
          purchase_yearly: scope.purchase_yearly ? scope.purchase_yearly : 0,
          emission_co2_jan: scope.emission_co2_jan ? scope.emission_co2_jan : 0,
          emission_co2_feb: scope.emission_co2_feb ? scope.emission_co2_feb : 0,
          emission_co2_mar: scope.emission_co2_mar ? scope.emission_co2_mar : 0,
          emission_co2_apr: scope.emission_co2_apr ? scope.emission_co2_apr : 0,
          emission_co2_may: scope.emission_co2_may ? scope.emission_co2_may : 0,
          emission_co2_jun: scope.emission_co2_jun ? scope.emission_co2_jun : 0,
          emission_co2_jul: scope.emission_co2_jul ? scope.emission_co2_jul : 0,
          emission_co2_aug: scope.emission_co2_aug ? scope.emission_co2_aug : 0,
          emission_co2_sep: scope.emission_co2_sep ? scope.emission_co2_sep : 0,
          emission_co2_oct: scope.emission_co2_oct ? scope.emission_co2_oct : 0,
          emission_co2_nov: scope.emission_co2_nov ? scope.emission_co2_nov : 0,
          emission_co2_dec: scope.emission_co2_dec ? scope.emission_co2_dec : 0,
        };
      });

      const scope = {
        status: 2,
        reason: formData.reason,
        evidence: formData.evidence,
        ignore_fill: formData.ignore_fill,
        itens: hoItems,
      };
      
      const result = await scope3Service.saveHomeWorkDisplacementHomeOffice(
        inventorySelected.company.id,
        inventorySelected.year,
        scope
      );
      
      if (result && !result.error) {
        history.goBack();

        setErrorApi(undefined);

        toast.success("Operação realizada com sucesso!");
      }

      if (result.error) {
        setErrorApi(result.error);
      }
    }

    setLoadingModal(false);
  };

  const { isCommonUser } = useAuth();

  async function getInventorysById(company_id: number, year: number, scope: number) {
    setLoadingModal(true);

    const hoItens = await homeOfficeService.getByParams({
      limit: 9000,
      page: 1,
    });

    const options = hoItens?.itens.map((item: any) => {
      return {
        label:
          item.name +
          " (" +
          (item.is_company
            ? item.company_name
            : item.city + " - " + item.state) +
          ")",
        city: item.city,
        state: item.state,
        value: item.id,
      };
    });

    console.error('Erro na API:', scope);

    setHomeEnergy(options);

    const inventory = await inventoryService.getById(company_id, year);
    const entity = await scope3Service.getHomeWorkDisplacementHomeOffice(company_id,year);

    const result = await inventoryService.getFiles(
      inventory.company.id,
      inventory.year,
      3,
      "deslocamento_casa_trabalho",
      "home_office"
    );
    setFiles(result.files);

    if (entity) {
      setValue("reason", entity.reason);
      setValue("evidence", entity.evidence);
      setValue("ignore_fill", entity.ignore_fill);

      setScopeSelected(entity);

      for (const item of entity?.itens) {
        item.only_view_yearly =
          item.purchase_jan > 0 ||
          item.purchase_feb > 0 ||
          item.purchase_mar > 0 ||
          item.purchase_apr > 0 ||
          item.purchase_may > 0 ||
          item.purchase_jun > 0 ||
          item.purchase_jul > 0 ||
          item.purchase_aug > 0 ||
          item.purchase_sep > 0 ||
          item.purchase_oct > 0 ||
          item.purchase_nov > 0 ||
          item.purchase_dec > 0;

        item.only_view_monthly = item.purchase_yearly > 0;
      }

      setScopeValues(entity?.itens);
      calculateTotals(entity?.itens);

      setIgnoreFill(entity.ignore_fill);
      setChangeHomeOffice(
        "load_scope_" + entity.id + "_scopes_" + entity?.itens.length
      );
    } else {
      setScopeSelected({});
      setIgnoreFill(false);

      setScopeValues([]);
      calculateTotals([]);

      setChangeHomeOffice("load_scope_0_scopes_0");
    }

    setInventorySelected(inventory);
    setLoadingModal(false);
  }

  function handleDeleteHomeOffice(index: number) {
    const itens = [];

    for (let i = 0; i < homeOffice?.length; i++) {
      if (i !== index) {
        itens.push(homeOffice[i]);
      }
    }

    setChangeHomeOffice("remove_scope_item_" + index);

    setValue("energyTypes", []);

    setScopeValues(itens);
    calculateTotals(itens);
  }

  const calculateTotals = (scopes: any) => {
    let purchaseJan = 0;
    let purchaseFeb = 0;
    let purchaseMar = 0;
    let purchaseApr = 0;
    let purchaseMay = 0;
    let purchaseJun = 0;
    let purchaseJul = 0;
    let purchaseAug = 0;
    let purchaseSep = 0;
    let purchaseOct = 0;
    let purchaseNov = 0;
    let purchaseDec = 0;
    let emissionCo2 = 0;
    let emissionCh4 = 0;
    let emissionN2o = 0;
    let purchaseTotal = 0;
    let purchaseYearly = 0;

    if (scopes) {
      for (const item of scopes) {
        if (item) {
          if (item.purchase_jan) {
            purchaseJan = purchaseJan + item.purchase_jan;
          }
          if (item.purchase_feb) {
            purchaseFeb = purchaseFeb + item.purchase_feb;
          }
          if (item.purchase_mar) {
            purchaseMar = purchaseMar + item.purchase_mar;
          }
          if (item.purchase_apr) {
            purchaseApr = purchaseApr + item.purchase_apr;
          }
          if (item.purchase_may) {
            purchaseMay = purchaseMay + item.purchase_may;
          }
          if (item.purchase_jun) {
            purchaseJun = purchaseJun + item.purchase_jun;
          }
          if (item.purchase_jul) {
            purchaseJul = purchaseJul + item.purchase_jul;
          }
          if (item.purchase_aug) {
            purchaseAug = purchaseAug + item.purchase_aug;
          }
          if (item.purchase_sep) {
            purchaseSep = purchaseSep + item.purchase_sep;
          }
          if (item.purchase_oct) {
            purchaseOct = purchaseOct + item.purchase_oct;
          }
          if (item.purchase_nov) {
            purchaseNov = purchaseNov + item.purchase_nov;
          }
          if (item.purchase_dec) {
            purchaseDec = purchaseDec + item.purchase_dec;
          }
          if (item.emission_co2) {
            emissionCo2 = emissionCo2 + item.emission_co2;
          }
          if (item.emission_ch4) {
            emissionCh4 = emissionCh4 + item.emission_ch4;
          }
          if (item.emission_n2o) {
            emissionN2o = emissionN2o + item.emission_n2o;
          }
          if (item.purchase_yearly) {
            purchaseYearly = purchaseYearly + item.purchase_yearly;
          }
          if (item.purchase_total) {
            purchaseTotal = purchaseYearly +
            purchaseJan + purchaseFeb + purchaseMar +
            purchaseApr + purchaseMay + purchaseJun +
            purchaseJul + purchaseAug + purchaseSep +
            purchaseOct + purchaseNov + purchaseDec;
          }
        }
      }
    }

    setPurchaseJan(purchaseJan);
    setPurchaseFeb(purchaseFeb);
    setPurchaseMar(purchaseMar);
    setPurchaseApr(purchaseApr);
    setPurchaseMay(purchaseMay);
    setPurchaseJun(purchaseJun);
    setPurchaseJun(purchaseJul);
    setPurchaseJul(purchaseJul);
    setPurchaseAug(purchaseAug);
    setPurchaseSep(purchaseSep);
    setPurchaseOct(purchaseOct);
    setPurchaseNov(purchaseNov);
    setPurchaseDec(purchaseDec);
    setEmissionCo2(emissionCo2);
    setEmissionCh4(emissionCh4);
    setEmissionN2o(emissionN2o);
    setPurchaseTotal(purchaseTotal);
    setPurchaseYearly(purchaseYearly);
  };

  const handleChangeValue = (index: number, ignore_field?: string) => {
    const purchaseJan = parseFloat(
      homeOffice[index].purchase_jan ? homeOffice[index].purchase_jan : 0
    );
    const purchaseFeb = parseFloat(
      homeOffice[index].purchase_feb ? homeOffice[index].purchase_feb : 0
    );
    const purchaseMar = parseFloat(
      homeOffice[index].purchase_mar ? homeOffice[index].purchase_mar : 0
    );
    const purchaseApr = parseFloat(
      homeOffice[index].purchase_apr ? homeOffice[index].purchase_apr : 0
    );
    const purchaseMay = parseFloat(
      homeOffice[index].purchase_may ? homeOffice[index].purchase_may : 0
    );
    const purchaseJun = parseFloat(
      homeOffice[index].purchase_jun ? homeOffice[index].purchase_jun : 0
    );
    const purchaseJul = parseFloat(
      homeOffice[index].purchase_jul ? homeOffice[index].purchase_jul : 0
    );
    const purchaseAug = parseFloat(
      homeOffice[index].purchase_aug ? homeOffice[index].purchase_aug : 0
    );
    const purchaseSep = parseFloat(
      homeOffice[index].purchase_sep ? homeOffice[index].purchase_sep : 0
    );
    const purchaseOct = parseFloat(
      homeOffice[index].purchase_oct ? homeOffice[index].purchase_oct : 0
    );
    const purchaseNov = parseFloat(
      homeOffice[index].purchase_nov ? homeOffice[index].purchase_nov : 0
    );
    const purchaseDec = parseFloat(
      homeOffice[index].purchase_dec ? homeOffice[index].purchase_dec : 0
    );

    const onlyViewYearly =
      purchaseJan > 0 ||
      purchaseFeb > 0 ||
      purchaseMar > 0 ||
      purchaseApr > 0 ||
      purchaseMay > 0 ||
      purchaseJun > 0 ||
      purchaseJul > 0 ||
      purchaseAug > 0 ||
      purchaseSep > 0 ||
      purchaseOct > 0 ||
      purchaseNov > 0 ||
      purchaseDec > 0;

    const purchaseYearly = onlyViewYearly ? 0 : homeOffice[index].purchase_yearly;

    const onlyViewMonthly = purchaseYearly > 0;


    const scope = {
      purchase_jan: purchaseJan,
      purchase_feb: purchaseFeb,
      purchase_mar: purchaseMar,
      purchase_apr: purchaseApr,
      purchase_may: purchaseMay,
      purchase_jun: purchaseJun,
      purchase_jul: purchaseJul,
      purchase_aug: purchaseAug,
      purchase_sep: purchaseSep,
      purchase_oct: purchaseOct,
      purchase_nov: purchaseNov,
      purchase_dec: purchaseDec,
      purchase_total: purchaseTotal,
      purchase_yearly: purchaseYearly      
    };  
    
    async function calculate() {
      const result =
        await scope3Service.calculateHomeWorkDisplacementHomeOffice(populateScopeParams(scope, inventorySelected.year));
      
      if (result) {
        homeOffice[index].only_view_yearly = onlyViewYearly;
        homeOffice[index].only_view_monthly = onlyViewMonthly;

        homeOffice[index].emission_co2 = result.emission_co2;
        homeOffice[index].emission_ch4 = result.emission_ch4;
        homeOffice[index].emission_n2o = result.emission_n2o;
        homeOffice[index].purchase_total = result.purchase_total;
        homeOffice[index].emission_co2_jan = result.emission_co2_jan;
        homeOffice[index].emission_co2_feb = result.emission_co2_feb;
        homeOffice[index].emission_co2_mar = result.emission_co2_mar;
        homeOffice[index].emission_co2_apr = result.emission_co2_apr;
        homeOffice[index].emission_co2_may = result.emission_co2_may;
        homeOffice[index].emission_co2_jun = result.emission_co2_jun;
        homeOffice[index].emission_co2_jul = result.emission_co2_jul;
        homeOffice[index].emission_co2_aug = result.emission_co2_aug;
        homeOffice[index].emission_co2_sep = result.emission_co2_sep;
        homeOffice[index].emission_co2_oct = result.emission_co2_oct;
        homeOffice[index].emission_co2_nov = result.emission_co2_nov;
        homeOffice[index].emission_co2_dec = result.emission_co2_dec;

        setChangeHomeOffice(
          "change_scope_item_" +
            index +
            "_" +
            purchaseJan +
            "_" +
            purchaseFeb +
            "_" +
            purchaseMar +
            "_" +
            purchaseApr +
            "_" +
            purchaseMay +
            "_" +
            purchaseJun +
            "_" +
            purchaseJul +
            "_" +
            purchaseAug +
            "_" +
            purchaseSep +
            "_" +
            purchaseOct +
            "_" +
            purchaseNov +
            "_" +
            purchaseDec +
            "_" +
            purchaseYearly
        );

        setScopeValues(homeOffice, ignore_field);
        calculateTotals(homeOffice);
      }
    }

    calculate();
  };

  const setScopeValues = (itens: any, ignore_field?: string) => {
    let changeScope = "change_scope_item_";

    if (itens) {
      for (let i = 0; i < itens?.length; i++) {
        const item = itens[i];

        setValue(`homeOffice.${i}.source`, item.source);
        setValue(`homeOffice.${i}.description`, item.description);

        if ("purchase_jan" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jan`,
            formatPrice3(item.purchase_jan, false)
          );
        }

        if ("purchase_feb" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_feb`,
            formatPrice3(item.purchase_feb, false)
          );
        }

        if ("purchase_mar" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_mar`,
            formatPrice3(item.purchase_mar, false)
          );
        }

        if ("purchase_apr" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_apr`,
            formatPrice3(item.purchase_apr, false)
          );
        }

        if ("purchase_may" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_may`,
            formatPrice3(item.purchase_may, false)
          );
        }

        if ("purchase_jun" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jun`,
            formatPrice3(item.purchase_jun, false)
          );
        }

        if ("purchase_jul" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_jul`,
            formatPrice3(item.purchase_jul, false)
          );
        }

        if ("purchase_aug" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_aug`,
            formatPrice3(item.purchase_aug, false)
          );
        }

        if ("purchase_sep" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_sep`,
            formatPrice3(item.purchase_sep, false)
          );
        }

        if ("purchase_oct" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_oct`,
            formatPrice3(item.purchase_oct, false)
          );
        }

        if ("purchase_nov" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_nov`,
            formatPrice3(item.purchase_nov, false)
          );
        }

        if ("purchase_dec" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_dec`,
            formatPrice3(item.purchase_dec, false)
          );
        }

        if ("purchase_yearly" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_yearly`,
            formatPrice3(item.purchase_yearly, false)
          );
        }

        if ("purchase_total" !== ignore_field) {
          setValue(
            `homeOffice.${i}.purchase_total`,
            formatPrice3(item.purchase_total, false)
          );
        }

        if ("emission_co2" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2`,
            formatPrice3(item.emission_co2, false)
          );
        }

        if ("emission_ch4" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_ch4`,
            formatPrice3(item.emission_ch4, false)
          );
        }

        if ("emission_n2o" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_n2o`,
            formatPrice3(item.emission_n2o, false)
          );
        }

        if ("emission_co2_jan" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jan`,
            formatPrice3(item.emission_co2_jan, false)
          );
        }

        if ("emission_co2_feb" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_feb`,
            formatPrice3(item.emission_co2_feb, false)
          );
        }

        if ("emission_co2_mar" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_mar`,
            formatPrice3(item.emission_co2_mar, false)
          );
        }

        if ("emission_co2_apr" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_apr`,
            formatPrice3(item.emission_co2_apr, false)
          );
        }

        if ("emission_co2_may" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_may`,
            formatPrice3(item.emission_co2_may, false)
          );
        }

        if ("emission_co2_jun" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jun`,
            formatPrice3(item.emission_co2_jun, false)
          );
        }

        if ("emission_co2_jul" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_jul`,
            formatPrice3(item.emission_co2_jul, false)
          );
        }

        if ("emission_co2_aug" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_aug`,
            formatPrice3(item.emission_co2_aug, false)
          );
        }

        if ("emission_co2_sep" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_sep`,
            formatPrice3(item.emission_co2_sep, false)
          );
        }

        if ("emission_co2_oct" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_oct`,
            formatPrice3(item.emission_co2_oct, false)
          );
        }

        if ("emission_co2_nov" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_nov`,
            formatPrice3(item.emission_co2_nov, false)
          );
        }

        if ("emission_co2_dec" !== ignore_field) {
          setValue(
            `homeOffice.${i}.emission_co2_dec`,
            formatPrice3(item.emission_co2_dec, false)
          );
        }

        changeScope =
          changeScope +
          item.car_fleet_id +
          "_" +
          item.year +
          "_" +
          item.purchase_jan +
          "_" +
          item.purchase_feb +
          "_" +
          item.purchase_mar +
          "_" +
          item.purchase_apr +
          "_" +
          item.purchase_may +
          "_" +
          item.purchase_jun +
          "_" +
          item.purchase_jul +
          "_" +
          item.purchase_aug +
          "_" +
          item.purchase_sep +
          "_" +
          item.purchase_oct +
          "_" +
          item.purchase_nov +
          "_" +
          item.purchase_dec +
          "_" +
          item.purchase_yearly;
      }
    }

    setHomeOffice(itens);
    setChangeHomeOffice(changeScope);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não for dígito (números)
    value = value.replace(/(\d)(\d{3})$/, '$1,$2'); // Adiciona uma vírgula antes dos últimos cinco dígitos
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.'); // Adiciona um ponto antes de cada grupo de três dígitos

    // Verifica se o valor é igual a zero após a formatação e remove os zeros à esquerda
    if (value === '') {
        value = '0';
    }

    return value;
  }

  const downloadFile = (filename: string) => {
    window.open(filename, "_blank");
  };

  function handleDeleteFile(id: number) {
    async function deleteFile(id: number) {
      const result = await inventoryService.deleteFile(id);

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          "deslocamento_casa_trabalho",
          "home_office"
        );

        setFiles(result.files);
      }
    }

    deleteFile(id);
  }
  
  const handleAttachmentChange = (event: any) => {
    async function saveImage(base64?: string, filename?: string) {
      const image = {
        type: 3,
        name: "deslocamento_casa_trabalho",
        file: base64,
        filename: filename,
        second_name: "home_office",
      };
      
      const result = await inventoryService.saveFile(
        inventorySelected.company.id,
        inventorySelected.year,
        image
      );

      if (result && !result.error) {
        const result = await inventoryService.getFiles(
          inventorySelected.company.id,
          inventorySelected.year,
          3,
          "deslocamento_casa_trabalho",
          "home_office"
        );

        setFiles(result.files);
      }
    }

    for (const file of event.target.files) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        const base64 = reader.result;

        saveImage(base64?.toString(), file.name);
      };
    }
  };

  const handleChangeHomeOffice = (e: any) => {
    async function retrieveServiceById(id: any) {
      const result = await homeOfficeService.getById(id);

      if (result) {
        const item = {
          city: result.city,
          state: result.state,
          private_name: result.name,
          company_name: result.company_name,
          only_view_monthly: false,
          only_view_yearly: false,
        };

        const index = homeOffice.length;

        const itens = homeOffice.concat(item);

        setChangeHomeOffice("new_scope_item_" + index);

        setScopeValues(itens);
        calculateTotals(itens);
      }
    }

    if (e) {
      retrieveServiceById(e.value);
    }

    setValue("home_office_params", []);
  };

  const handleOpenModalImport = () => {
    setOpenModalImport(!openModalImport);
  };

  const handleImport = (type?: number, data?: any[]) => {
    async function importData() {
      if (data) {
        setLoadingModal(true);

        const errors: any[] = [];
        const itens = homeOffice;

        const monthsDefault: any = await utilsService.getDifferenceDaysMonth({
          year: inventorySelected.year,
        });

        for (let i = 1; i < data?.length; i++) {
          
          const city = data[i][4];
          const state = data[i][5];
          
          if (city || state) {
            let obj: any = undefined;
            
            for (const t of homeEnergy) {
              if (t.city === city && t.state === state) {
                obj = t;
                break;
              }
            }
            
            if (obj) {
              
              const homeObj = await homeOfficeService.getById(obj.value);
              

              if (homeObj) {
                const register = data[i][0];
                const admission = data[i][1];
                const resignation = data[i][2];
                const workload = data[i][3];
                
                
                let months: any = {};
                if (admission && resignation) {
                  months = await utilsService.getDifferenceDaysMonth({
                    start_date: admission,
                    end_date: resignation,
                    year: inventorySelected.year,
                  });
                } else if (admission) {
                  months = await utilsService.getDifferenceDaysMonth({
                    start_date: admission,
                    year: inventorySelected.year,
                  });
                } else if (resignation) {
                  months = await utilsService.getDifferenceDaysMonth({
                    end_date: resignation,
                    year: inventorySelected.year,
                  });
                } else {
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  months = await utilsService.getDifferenceDaysMonth({
                    year: inventorySelected.year,
                  });
                }

                let work_default = 0;

                if (homeObj.devices) {
                  for (const i of homeObj.devices) {
                    if (i.hours > work_default) {
                      work_default = i.hours;
                    }
                  }
                } else {
                  work_default = 8;
                }

                const factor_by_day = workload / work_default;
                
                const meses = [
                  "jan", "feb", "mar", "apr", "may", "jun",
                  "jul", "aug", "sep", "oct", "nov", "dec"
                ];
                
                const purchaseValues: any = {};
                
                for (const month of meses) {
                  purchaseValues[month] = parseFloat(
                    (
                      ((monthsDefault[month] / monthsDefault[month]) *
                        homeObj.consumption *
                        factor_by_day) /
                      1000
                    ).toFixed(4)
                  );
                  
                }
              
                const scope = {
                  city: city,
                  state: state,
                  source: register,
                  description: "Pessoa " + register,
                  purchase_jan: purchaseValues["jan"],
                  purchase_feb: purchaseValues["feb"],
                  purchase_mar: purchaseValues["mar"],
                  purchase_apr: purchaseValues["apr"],
                  purchase_may: purchaseValues["may"],
                  purchase_jun: purchaseValues["jun"],
                  purchase_jul: purchaseValues["jul"],
                  purchase_aug: purchaseValues["aug"],
                  purchase_sep: purchaseValues["sep"],
                  purchase_oct: purchaseValues["oct"],
                  purchase_nov: purchaseValues["nov"],
                  purchase_dec: purchaseValues["dec"],
                  emission_co2: 0,
                  emission_ch4: 0,
                  emission_n2o: 0,
                  purchase_total: 0,
                  purchase_yearly: 0,
                  emission_co2_jan: 0,
                  emission_co2_feb: 0,
                  emission_co2_mar: 0,
                  emission_co2_apr: 0,
                  emission_co2_may: 0,
                  emission_co2_jun: 0,
                  emission_co2_jul: 0,
                  emission_co2_aug: 0,
                  emission_co2_sep: 0,
                  emission_co2_oct: 0,
                  emission_co2_nov: 0,
                  emission_co2_dec: 0,
                  only_view_yearly: true,
                  only_view_monthly: false,
                  company_name: homeObj.name,
                };                 

                const result =
                  await scope3Service.calculateHomeWorkDisplacementHomeOffice(populateScopeParams(scope, inventorySelected.year));

                if (result) {
                  scope.emission_co2 = result.emission_co2;
                  scope.emission_ch4 = result.emission_ch4;
                  scope.emission_n2o = result.emission_n2o;
                  scope.purchase_total = result.purchase_total;
                  scope.emission_co2_jan = result.emission_co2_jan;
                  scope.emission_co2_feb = result.emission_co2_feb;
                  scope.emission_co2_mar = result.emission_co2_mar;
                  scope.emission_co2_apr = result.emission_co2_apr;
                  scope.emission_co2_may = result.emission_co2_may;
                  scope.emission_co2_jun = result.emission_co2_jun;
                  scope.emission_co2_jul = result.emission_co2_jul;
                  scope.emission_co2_aug = result.emission_co2_aug;
                  scope.emission_co2_sep = result.emission_co2_sep;
                  scope.emission_co2_oct = result.emission_co2_oct;
                  scope.emission_co2_nov = result.emission_co2_nov;
                  scope.emission_co2_dec = result.emission_co2_dec;

                  itens.push(scope);
                }
              } else {
                errors.push({ id: i, city: city, state: state });
              }
            } else {
              errors.push({ id: i, city: city, state: state });
            }
          }
        }

        setChangeHomeOffice(`import_scope_data_${data?.length}`);

        setScopeValues(itens);
        calculateTotals(itens);

        if (errors && errors.length > 0) {
          handleOpenModalError(errors);
        }
      }

      setLoadingModal(false);
    }

    if (data) {
      importData();
    }
  };

  const handleOpenModalError = (data?: any) => {
    setImportErrors(data);
    setOpenModalError(!openModalError);
  };

  const populateScopeParams = (scope: any, year: number): EletricityParams => {
    return {
      purchase_jan: scope.purchase_jan,
      purchase_feb: scope.purchase_feb,
      purchase_mar: scope.purchase_mar,
      purchase_apr: scope.purchase_apr,
      purchase_may: scope.purchase_may,
      purchase_jun: scope.purchase_jun,
      purchase_jul: scope.purchase_jul,
      purchase_aug: scope.purchase_aug,
      purchase_sep: scope.purchase_sep,
      purchase_oct: scope.purchase_oct,
      purchase_nov: scope.purchase_nov,
      purchase_dec: scope.purchase_dec,
      emission_co2: scope.emission_co2,
      emission_ch4: scope.emission_ch4,
      emission_n2o: scope.emission_n2o,
      purchase_total: scope.purchase_total,
      purchase_yearly: scope.purchase_yearly,
      year: year  
    };
  }

  return (
    <Container>
      <GoBack />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 25,
            justifyContent: "space-between",
            marginBottom: 20,
          }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TitleText level={1} title={"Inventário: " + inventorySelected?.year + " - " + inventorySelected?.company?.corporate_name }/>
                <TitleText level={4} title={"Escopo 03 - " + title} />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo} 
                style={{ margin: "auto 10px auto auto" }}>
                Orientações
              </Button>
            )}
            
            {!isCommonUser && (
              <Button color="green" size="md" style={{ margin: "auto" }}>
                Salvar
              </Button>
            )}
          </div>
        </div>

        {!isCommonUser && (
          <ReportRecordsCheckbox
            register={register}
            setIgnoreFill={setIgnoreFill}
          />
        )}

        <div style={{width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",}}/>

        {ignoreFill && (
          <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
            <label
              style={{
                marginTop: 15,
                fontFamily: "'Roboto', sans-serif",
                fontSize: "16px",
                lineHeight: "19px",
                color: "#31363F",
                opacity: "0.8",
                fontWeight: "bold",
              }}
            >
              Informe abaixo uma justificativa para o não preenchimento do
              escopo selecionado
            </label>

            <textarea
              readOnly={isCommonUser}
              style={{ width: "95%", height: 100 }}
              {...register("reason", { required: true })}
            />

            {errors.reason && (
              <span className="requiredLabel">Esse campo é obrigatório</span>
            )}
          </div>
        )}

        {!ignoreFill && (
          <div
            style={{
              width: "100%",
              borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
            }}
          />
        )}

        {!ignoreFill && (
          <div
            style={{
              overflow: "hidden auto",
              position: "relative",
              marginTop: 0,
              height: "calc(100vh - 200px)",
              maxHeight: "calc(100vh - 200px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                justifyContent: "space-between",
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <span
                  style={{
                    textAlign: "start",
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 5,
                  }}
                >
                  Quantidade total de eletricidade consumida em Home Office
                </span>
                <span
                  style={{ textAlign: "start", fontSize: 12, marginTop: 5 }}
                >
                  Selecione, a localidade ao lado, para adicionar nova linha
                </span>
              </div>

              <div style={{ textAlign: "left", marginRight: 20, width: "30%" }}>
                {!isCommonUser && homeEnergy && (
                  <div
                    style={{
                      marginTop: 15,
                      marginRight: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Controller
                      control={control}
                      name="home_office_params"
                      render={({ field }) => (
                        <Select
                          placeholder="[Selecione]"
                          {...field}
                          styles={{
                            control: (styles) => ({
                              ...styles,
                              width: "250px",
                              height: 40,
                              backgroundColor: "rgb(246, 246, 246)",
                            }),
                            option: (styles) => {
                              return { ...styles, color: "#31363F" };
                            },
                          }}
                          defaultValue={getValues("home_office_params")}
                          options={homeEnergy}
                          onChange={handleChangeHomeOffice}
                        />
                      )}
                    />

                    <button
                      className="button-view"
                      style={{ height: 40, marginLeft: 5 }}
                      onClick={() => handleOpenModalImport()}
                      type="button"
                    >
                      Importar Dados
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div
              style={{
                overflow: "auto",
                position: "relative",
                marginTop: 20,
                width: "calc(100vw - 300px)",
                maxHeight: "calc(100vh - 360px)",
              }}
            >
              {changeHomeOffice && homeOffice && homeOffice.length > 0 && (
                <table
                  style={{
                    maxWidth: "100%",
                    width: "4000px",
                    borderCollapse: "collapse",
                  }}
                  className="table-scope"
                >
                  <thead>
                    <tr>
                      <th
                        className="head-tr-item"
                        style={{ width: 360 }}
                        colSpan={3}
                      >
                        {"Fonte"}
                      </th>
                      <th
                        className="head-tr-item"
                        style={{ width: 200 }}
                        colSpan={13}
                      >
                        {"Eletricidade Comprada (MWh)"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={3}
                      >
                        {"Eletricidade total comprada (MWh)"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {"Emissões de CO₂ (t)"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {"Emissões de CH₄ (t)"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                        rowSpan={3}
                      >
                        {"Emissões de N₂O (t)"}
                      </th>
                      {!isCommonUser && (
                        <th
                          className="head-tr-item"
                          style={{ width: 50 }}
                          rowSpan={3}
                        />
                      )}
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {"Registro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {"Descrição"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 200 }}
                        rowSpan={2}
                      >
                        {"Companhia de energia"}
                      </th>
                      <th
                        className="head-tr-item"
                        style={{ width: 200 }}
                        colSpan={12}
                      >
                        {"Relate aqui a compra mensal de eletricidade (MWh)"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 250 }}
                        rowSpan={2}
                      >
                        {"Relate aqui a compra anual de eletricidade (MWh)"}
                      </th>
                    </tr>
                    <tr>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Janeiro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Fevereiro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Março"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Abril"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Maio"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Junho"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Julho"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Agosto"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Setembro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Outubro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Novembro"}
                      </th>
                      <th
                        className="head-tr-item-bottom"
                        style={{ width: 100 }}
                      >
                        {"Dezembro"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homeOffice.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(`homeOffice.${index}.source`, {
                                  required: true,
                                })}
                                onChange={(event) => {
                                  setValue(
                                    `homeOffice.${index}.source`,
                                    event.target.value
                                  );
                                  homeOffice[index].source = event.target.value;
                                }}
                              />
                            </td>
                            <td>
                              <input
                                readOnly={isCommonUser}
                                style={{ width: 200 }}
                                className="input-item"
                                {...register(
                                  `homeOffice.${index}.description`,
                                  { required: true }
                                )}
                                onChange={(event) => {
                                  setValue(
                                    `homeOffice.${index}.description`,
                                    event.target.value
                                  );
                                  homeOffice[index].description =
                                    event.target.value;
                                }}
                              />
                            </td>
                            <td style={{ width: 200 }}>
                              <span
                                style={{
                                  marginLeft: 5,
                                  width: 200,
                                  fontSize: 12,
                                  fontWeight: "bold",
                                  display: "inline-block",
                                }}
                              >
                                {homeOffice[index].private_name}
                              </span>

                              <br />

                              {homeOffice[index].city &&
                                homeOffice[index].state && (
                                  <span
                                    style={{
                                      marginLeft: 5,
                                      width: 300,
                                      fontSize: 12,
                                      display: "inline-block",
                                    }}
                                  >
                                    <b>{"Cidade: "}</b>
                                    {homeOffice[index].city +
                                      " - " +
                                      homeOffice[index].state}
                                  </span>
                                )}

                              {homeOffice[index].company_name && (
                                <span
                                  style={{
                                    marginLeft: 5,
                                    width: 300,
                                    fontSize: 12,
                                    display: "inline-block",
                                  }}
                                >
                                  <b>{"Companhia: "}</b>
                                  {homeOffice[index].company_name}
                                </span>
                              )}
                            </td>

                            <td>
                              {/* Começo da declaração de valores mensais */}
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_jan`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);
                                    homeOffice[index].purchase_jan = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_jan`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_jan");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_feb`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_feb = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_feb`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_feb");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_mar`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_mar = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_mar`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_mar");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_apr`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_apr = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_apr`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_apr");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_may`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_may = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_may`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_may");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_jun`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_jun = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_jun`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_jun");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_jul`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_jul = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_jul`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_jul");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_aug`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_aug = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_aug`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_aug");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_sep`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_sep = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_sep`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_sep");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_oct`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_oct = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_oct`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_oct");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_nov`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_nov = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_nov`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_nov");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {homeOffice[index].only_view_monthly === false && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_dec`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_dec = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_dec`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_dec");
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {!homeOffice[index].only_view_yearly && (
                                <input
                                  readOnly={isCommonUser}
                                  style={{ width: 100, textAlign: "center" }}
                                  className="input-item"
                                  {...register(
                                    `homeOffice.${index}.purchase_yearly`
                                  )}
                                  onChange={(event) => {
                                    const { value, formatValue } =
                                      formatInputNumber(event);

                                    homeOffice[index].purchase_yearly = value;

                                    setValue(
                                      `homeOffice.${index}.purchase_yearly`,
                                      formatValue
                                    );

                                    handleChangeValue(index, "purchase_yearly");
                                  }}
                                />
                              )}
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: "inline-block",
                                  textAlign: "right",
                                }}
                              >
                                {formatPrice3(
                                  homeOffice[index].purchase_total,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: "inline-block",
                                  textAlign: "right",
                                }}
                              >
                                {formatPrice3(
                                  homeOffice[index].emission_co2,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: "inline-block",
                                  textAlign: "right",
                                }}
                              >
                                {formatPrice3(
                                  homeOffice[index].emission_ch4,
                                  false
                                )}
                              </span>
                            </td>
                            <td style={{ width: 100 }} rowSpan={2}>
                              <span
                                style={{
                                  marginRight: 5,
                                  width: 100,
                                  fontSize: 12,
                                  display: "inline-block",
                                  textAlign: "right",
                                }}
                              >
                                {formatPrice3(
                                  homeOffice[index].emission_n2o,
                                  false
                                )}
                              </span>
                            </td>
                            {!isCommonUser && (
                              <td style={{ textAlign: "center" }} rowSpan={2}>
                                <div
                                  onClick={() => handleDeleteHomeOffice(index)}
                                  style={{
                                    cursor: "pointer",
                                    textAlign: "center",
                                    width: 70,
                                  }}
                                >
                                  <img src={TrashIcon} alt="lixeirinha"/>
                                </div>
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              className="subhead-item"
                              style={{ paddingRight: 5, textAlign: "right" }}
                            >
                              {"Emissões mensais de CO₂ (t)"}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_jan,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_feb,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_mar,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_apr,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_may,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_jun,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_jul,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_aug,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_sep,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_oct,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_nov,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }}>
                              {homeOffice[index].only_view_monthly === false && (
                                <span
                                  style={{
                                    marginRight: 5,
                                    width: 100,
                                    fontSize: 12,
                                    display: "inline-block",
                                    textAlign: "right",
                                  }}
                                >
                                  {formatPrice3(
                                    homeOffice[index].emission_co2_dec,
                                    false
                                  )}
                                </span>
                              )}
                            </td>
                            <td style={{ width: 100 }} />
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: "bold" }}>
                      <td
                        colSpan={3}
                        className="head-tr-item"
                        style={{ paddingRight: 5, textAlign: "right" }}
                      >
                        {"Total"}
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseJan, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseFeb, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseMar, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseApr, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseMay, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseJun, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseJul, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseAug, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseSep, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseOct, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseNov, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseDec, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseYearly, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(purchaseTotal, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(emissionCo2, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(emissionCh4, false)}
                        </span>
                      </td>
                      <td className="head-tr-item">
                        <span
                          style={{
                            marginRight: 5,
                            width: 100,
                            fontSize: 12,
                            textAlign: "right",
                            display: "inline-block",
                          }}
                        >
                          {formatPrice3(emissionN2o, false)}
                        </span>
                      </td>
                      {!isCommonUser && <td className="head-tr-item" />}
                    </tr>
                  </tfoot>
                </table>
              )}

              {!homeOffice.length && (
                <Uninformed
                  text={
                    isCommonUser
                      ? `Nenhum registro foi informado`
                      : `Nenhum registro foi informado, favor selecionar para
                    dar prosseguimento`
                  }
                />
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginBottom: 10,
                }}
              >
                {errors && errors.homeOffice && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      margin: 10,
                    }}
                  >
                    <span
                      className="requiredLabel"
                      style={{ fontSize: 14, marginTop: 10 }}
                    >
                      * Registro da fonte e Descrição da fonte são obrigatórios
                    </span>
                  </div>
                )}
              </div>
            </div>

            {!ignoreFill && changeHomeOffice && (
              <div
                style={{
                  borderStyle: "dashed",
                  margin: 5,
                  borderWidth: "1px",
                  width: "calc(100vw - 330px)",
                  maxWidth: "calc(100vw - 330px)",
                  borderColor: "#9E9E9E",
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 15,
                }}
              >
                <span style={{ margin: 10, width: "70%" }}>
                  Emissões totais em CO₂ equivalente (toneladas métricas):
                </span>
                <span
                  style={{
                    fontWeight: "bold",
                    margin: 10,
                    textAlign: "right",
                    width: "30%",
                  }}
                >
                  {formatPrice3(emissionCo2, false)}
                </span>
              </div>
            )}

            {!ignoreFill && (
              <div
                style={{ display: "flex", flexDirection: "column", margin: 10 }}
              >
                <div
                  style={{
                    width: "100%",
                    borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 5,
                    marginRight: 30,
                  }}
                >
                  <span
                    style={{
                      textAlign: "start",
                      fontSize: 16,
                      marginTop: 5,
                      marginLeft: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Evidências do escopo informado
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 10,
                  }}
                >
                  <label
                    style={{ marginTop: 5, fontFamily: "'Roboto', sans-serif" }}
                  >
                    Observações sobre o escopo informado.
                  </label>

                  <textarea
                    readOnly={isCommonUser}
                    style={{ width: "100%", height: 100 }}
                    {...register("evidence")}
                  />

                  {errors.evidence && (
                    <span className="requiredLabel">
                      Esse campo é obrigatório
                    </span>
                  )}

                  <ScopeEvidenceAttachments
                    files={files}
                    onDeleteFile={handleDeleteFile}
                    onDownloadFile={downloadFile}
                    onChangeAttachment={handleAttachmentChange}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </form>

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TitleText level={2} title="Orientações gerais" />
        </div>

        <div style={{ marginTop: 10, width: 550 }}>
          {guidelines && <span style={{ fontSize: 12 }}>{Parser(guidelines)}</span>}
        </div>
      </Modal>

      {openModalImport && (
        <ModalImportData
          entityType={1}
          isOpen={openModalImport}
          handleModalVisible={handleOpenModalImport}
          entityName="Quantidade de eletricidade consumida em Home Office"
          templateFileName="template_displacement_Home.xlsx"
          handleImportData={handleImport}
        />
      )}

      {openModalError && importErrors && (
        <Modal
          isOpen={openModalError}
          isPayOrTypeClient={true}
          handleModalVisible={handleOpenModalError}
        >
          <div style={{ margin: 5, textAlign: "left" }}>
            <TitleText
              level={3}
              title="Falha na importação de dados"
              color="red"
            />

            <span style={{ textAlign: "start", fontSize: 14, marginTop: 5 }}>
              Ocorreram falhas na importação dos dados previstos. Abaixo maiores
              detalhes.
            </span>
          </div>

          <div style={{ margin: 10, textAlign: "center", cursor: "pointer" }}>
            <table
              style={{ width: "100%", borderCollapse: "collapse" }}
              className="table-scope"
            >
              <thead>
                <tr
                  style={{
                    borderColor: "white",
                    borderWidth: 1,
                    borderStyle: "solid",
                  }}
                >
                  <th
                    style={{
                      backgroundColor: "#D8D7D7",
                      fontSize: 12,
                      height: 25,
                    }}
                    colSpan={3}
                  >
                    {"Falhas de Importação"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {importErrors.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <tr
                        style={{
                          borderColor: "white",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                      >
                        <td
                          style={{
                            background: "#F6F6F6",
                            borderColor: "white",
                            borderWidth: 1,
                            borderStyle: "solid",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: 5,
                              width: "20%",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            {"Linha " + item.id}
                          </span>
                        </td>
                        <td
                          style={{
                            background: "#F6F6F6",
                            borderColor: "white",
                            borderWidth: 1,
                            borderStyle: "solid",
                          }}
                        >
                          <span
                            style={{
                              marginLeft: 5,
                              width: "80%",
                              fontSize: 12,
                              textAlign: "left",
                            }}
                          >
                            {"Lançamento não importado com sucesso: " +
                              item.city +
                              " - " +
                              item.state}
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {loadingModal && <Loading isOpen={loadingModal} />}
    </Container>
  );
};
